import { useCallback, useEffect, useReducer } from 'react'
import { IFormProps } from '../Interfaces/IFormProps'
import './_CommonForm.scss'
import { ItemFormReducer, initialItemFormState } from '../Reducers/CommonFormReducer'
import { Options } from '../Types/CommonHelper'
import { Mode, Operation } from '../Types/CommonType'
import { useAlert } from '../../../contexts/AlertContext'

interface CommonFormProps extends IFormProps {
    Form: React.ComponentType<any>;
    FormURL: string;
}

const CommonForm = (props: CommonFormProps) => {
    const { mode, onClose, onCloseNoSave, id, Form, FormURL } = props
    const [state, dispatch] = useReducer(ItemFormReducer, initialItemFormState())
    const { setAlert } = useAlert();

    const loadItem = useCallback(async () => {
        if (id === undefined) {
            return
        }
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''
        const response = await fetch(process.env.REACT_APP_METHODS_API + FormURL + '/' + id, Options)

        if (!response.ok) {
            setAlert({
                message: response.status + ':' + response.statusText
                , severity: 'error'
                , datetime: Date.now().toString()
            });
            dispatch({ type: 'Error', payload: `HTTP error! status: ${response.status}` })
        } else {
            const data = await response.json()
            dispatch({ type: 'ItemLoaded', payload: data[0] })
        }

    }, [id, FormURL])

    useEffect(() => {
        if (mode !== Mode.Add) {
            loadItem()
        } else {
            dispatch({ type: 'ItemLoaded', payload: initialItemFormState() })
        }
    }, [loadItem, mode])





    return (
        <>
            {state.itemLoaded && (
                <Form
                    item={state.item as any}
                    onClose={onClose}
                    onCloseNoSave={onCloseNoSave}
                    mode={mode}
                    operation={mode === Mode.Add ? Operation.Add : Operation.Edit}
                    counters={state.counters}
                />
            )}
        </>
    )
}

export default CommonForm
