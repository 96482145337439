import './_UFCheckBox.scss'
import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControlLabel, Switch } from '@mui/material'
import { IInputProps } from '../../Interfaces/IInputProps'
import { useTranslation } from 'react-i18next'

const UFCheckBox = (props: IInputProps) => {
    const { name, control, label, disabled } = props
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                <FormControlLabel
                    control={
                        <Switch
                            data-test={name}
                            name={name}
                            disabled={disabled}
                            checked={!!value}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    }
                    label={t(label)}
                />
            )}
        />
    )
}

export default UFCheckBox
