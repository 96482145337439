import './_LanguageSelector.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectChangeEvent, MenuItem, FormControl, Select, Grid, } from '@mui/material/'


interface LanguageOption {
    code: string
    name: string
}

const availableLanguages: LanguageOption[] = [
    { code: 'eu', name: 'Basque' }, // Add other language options as needed
    { code: 'es', name: 'Spanish' },
    { code: 'en', name: 'English' },
    // Add more language options here...
]

const LanguageSelector: React.FC = () => {
    const { t, i18n } = useTranslation()

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const languageCode = event.target.value as string
        i18n.changeLanguage(languageCode)
    }

    return (
            <Grid container display="flex" justifyContent="center" alignContent="center">
                <FormControl id="languages" variant="standard">
                    {/* <InputLabel id="language-selector-label" >
                        {t('Language')}
                    </InputLabel> */}
                    <Select

                        labelId="language-selector-label"
                        name="language-selector"
                        id="language-selector"
                        value={i18n.language}
                        onChange={handleChange}
                        label="Language"
                    >
                        {availableLanguages.map((language) => (
                            <MenuItem id={language.code} key={language.code} value={language.code}>
                                {t(language.name)}
                            </MenuItem>
                        ))}
                    </Select>

                </FormControl>
            </Grid>
        

    )
}

export default LanguageSelector
