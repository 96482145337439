import './_CustomDrawerMain.scss'
import { ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'
//import { useuserContext } from '../../../../contexts/userContext'

import { Mode } from '../../Types/CommonType'
import CustomDrawerRight from '../CustomDrawerRight/CustomDrawerRight'
import CustomDrawerLeft from '../CustomDrawerLeft/CustomDrawerLeft'
import CustomDrawerToolBar from '../CustomDrawerToolBar/CustomDrawerToolBar'
import { useAuth } from "../../../../contexts/AuthContext"

import UserForm from '../../../Views/Administration/User/Form/Regular/Main/UserForm'
const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'openRight' })<{
    open?: boolean
    openRight?: boolean
}>(({ theme, open, openRight }) => ({
    flexGrow: 1,
    //padding: theme.spacing(10, 0, 3, 0),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    /*marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    marginRight: `- ${drawerWidth}px`,
    ...(openRight && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),*/
}))

/*interface AppBarProps extends MuiAppBarProps {
    open?: boolean
    openRight?: boolean
}*/

/*const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'openRight',
})<AppBarProps>(({ theme, open, openRight }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100 % - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(openRight && {
        width: `calc(100 % - ${drawerWidth}px)`,
        marginRight: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))*/

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

/*const DrawerHeaderRight = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}))*/

interface customDrawerMainProps {
    children?: ReactNode
}

const CustomDrawerMain = (props: customDrawerMainProps) => {
    const [openLeft, setOpenLeft] = useState(false)
    const [openRight, setOpenRight] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const [openMyProfile, setOpenMyProfile] = useState(false)

    //const [user] = useuserContext()
    const { user } = useAuth();
    console.log('Inside CustomDrawerMain the image is:', user?.ProfileImg)

    const { t } = useTranslation()
    const [pageName, setPageName] = useState(user!.OrganizationDSCR)
    const [pageIcon, setPageIcon] = useState<string>('');
    const { children } = props

    const [closeProfileAlert, setCloseProfileAlert] = useState(false)
    const [reRenderForm, setReRenderForm] = useState(false)
    const handleDrawerOpenLeft = () => {
        handleOpenLeft(true)
        if (openRight) {
            setOpenRight(false)
        }

    }

    const handleDrawerOpenRight = () => {
        setOpenRight(true)
        if (openLeft) {
            setOpenLeft(false)
        }
    }



    const handleOpenLeft = (prm: boolean) => {
        setOpenLeft(prm)
        if (openRight) {
            setOpenRight(!prm)
        }
    }

    const handleOpenRight = (prm: boolean) => {
        setOpenRight(prm)
        if (openLeft) {
            setOpenLeft(!prm)
        }
    }



    const handleCloseNoSave = () => {
        setOpenMyProfile(false)
    }

    const handleCloseSave = () => {
        setReRenderForm(!reRenderForm)
        setCloseProfileAlert(true)
    }

    const handleOpenMyProfile = () => {
        setOpenMyProfile(true)
    }
    const handleSetPageName = (name: string) => {
        setPageName(name)
    }

    const handleSetPageIcon = (icon: string) => {
        setPageIcon(icon)
    }

    const handleCloseProfileAlert = () => {
        setCloseProfileAlert(false)
    }
    const handleOpenMyMessages = () => {
        setOpenMessage(true)
        if (openLeft) {
            setOpenLeft(false)
        }
        if (openRight) {
            setOpenRight(false)
        }
    }

    return (


        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <CustomDrawerToolBar
                handleSetPageName={handleSetPageName}
                handleSetPageIcon={handleSetPageIcon}
                pageIcon={pageIcon}
                pageName={pageName}
                handleOpenLeft={handleDrawerOpenLeft}
                handleOpenRight={handleDrawerOpenRight}
                openLeft={openLeft}
                openRight={openRight}
                openMessage={openMessage} />

            <CustomDrawerLeft
                handleSetPageName={handleSetPageName}
                handleSetPageIcon={handleSetPageIcon}
                handleOpenLeft={handleOpenLeft}
                openLeft={openLeft} />

            <CustomDrawerRight
                handleSetPageName={handleSetPageName}
                handleSetPageIcon={handleSetPageIcon}
                handleOpenRight={handleOpenRight}
                handleOpenMyProfile={handleOpenMyProfile}
                handleOpenMyMessages={handleOpenMyMessages}
                openRight={openRight} />

            <Main open={openLeft} openRight={openRight} sx={{ mt: '0rem' }}>
                <DrawerHeader />
                {openMyProfile && (
                    <UserForm
                        mode={Mode.DeleteEdit}
                        id={user!.OpUserID}
                        onClose={handleCloseSave}
                        onCloseNoSave={handleCloseNoSave}
                        reRender={reRenderForm}
                    />
                )}
                {children}
            </Main>
        </Box >




    )
}
export default CustomDrawerMain
