import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import CommonInfiniteScrollPaginatedList from './CommonInfiniteScrollPaginatedList/CommonInfiniteScrollPaginatedList';
import { Options } from '../Types/CommonHelper';
import { Methods } from '../../../Utils/Utils';
import { ItemReducer, initialItemState } from '../Reducers/CommonReducer';
import { useLoading } from '../../../contexts/LoadingContext';
import { useAlert } from '../../../contexts/AlertContext';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import AppsIcon from '@mui/icons-material/Apps';
import BarChartIcon from '@mui/icons-material/BarChart';
import CommonImageGrid from '../CommonImageGrid/CommonImageGrid';
import CommonPieChart from '../CommonPieChart/CommonPieChart';
interface CommonInfiniteScrollListProps<T> {

    url: string
    itemType?: string
    listItemTextAttributes: (item: T) => void
    pageSize?: number
    isVisibleNow?: boolean
    CRUDEForm?: React.ComponentType<any>;
    CRUDEFormURL?: string
    hideAddition?: boolean;
    variant?: 'default' | 'other';
    componentParams?: any;

}




function CommonInfiniteScrollList<T>(props: CommonInfiniteScrollListProps<T>) {
    const { url, pageSize, listItemTextAttributes, itemType, CRUDEForm, hideAddition, variant = 'default', componentParams, CRUDEFormURL } = props;

    const abortControllerRef = useRef<AbortController | null>(null);
    const [state, dispatch] = useReducer(ItemReducer, initialItemState(componentParams))
    const { setLoading, isLoading } = useLoading();
    const { setAlert } = useAlert();
    const { t } = useTranslation()

    const toggleBoxSx = {
        width: '100%',
        position: 'fixed',
        top: '65px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
        zIndex: 1100,
        flexDirection: 'column',
        padding: '5px',
        '@media (max-width: 600px)': {
            top: '55px', // Adjust this if your App Bar height changes
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    };





    const handleViewMode = (
        event: React.MouseEvent<HTMLElement>,
        newViewMode: string,
    ) => {
        if (newViewMode !== null && newViewMode !== state.viewMode) {
            dispatch({ type: 'ChangeViewMode', payload: newViewMode })
        }
    };




    useEffect(() => {
        dispatch({ type: 'Reset', componentParams: componentParams })
    }, [url]);

    const loadMore = async () => {
        try {

            if (state.displaySpinner === true) {
                setLoading(true)
            }

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            let tmpURL = ''
            if (componentParams.PageSize === undefined || componentParams.PageSize === -1) {
                tmpURL = process.env.REACT_APP_METHODS_API + url
            } else {
                tmpURL = process.env.REACT_APP_METHODS_API + url + `?page=${state.page}&pageSize=${componentParams.PageSize}&filter=${state.filter}`
            }


            Options.headers['x-access-token'] = localStorage.getItem('auth') + ''
            const response = await fetch(tmpURL, {
                headers: Options.headers,
                signal: abortController.signal,
            });

            if (response.status === 401) {
                dispatch({ type: 'SessionExpired' })

            } else {
                let newItems = await response.json();
                newItems = Methods.JSONUnminify(newItems).map((item: any) => {
                    return listItemTextAttributes(item)
                    //return item

                })

                if (state.page === 1 && newItems.length === 0) {
                    dispatch({ type: 'NoData' })
                } else {
                    dispatch({ type: 'ItemsLoaded', payload: newItems })

                    if (state.componentParams.showPieChart === true) {
                        dispatch({ type: 'LoadGraphItems', payload: Methods.aggregateByAttribute(newItems, state.componentParams.pieChartNameAttr, state.componentParams.pieChartValAttr) })
                        //console.log('tmpGraphData', JSON.stringify(state.GraphItems));
                    }

                }

                if (componentParams.PageSize === undefined || componentParams.PageSize === -1) {

                    dispatch({ type: 'HasMore', hasMore: false })
                } else {
                    if (newItems.length > 0) {

                        dispatch({ type: 'NextPage', page: state.page + 1 })
                    } else {

                        dispatch({ type: 'HasMore', hasMore: false })
                    }
                }
            }



        } catch (error: any) {
            if (error.name === 'AbortError') {
                dispatch({ type: 'Error', payload: 'Previous request canceled' })

                /*setAlert({
                    message: t('Previous request canceled')
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });*/
                console.log('Previous request canceled');
            } else {
                dispatch({ type: 'Error', payload: 'Error fetching options' })

                setAlert({
                    message: error.message
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                //console.error('Error fetching options');
            }



        } finally {
            setLoading(false)
        }
    };


    const debouncedFetch = useCallback(Methods.debounce(loadMore, 300), []);


    const handleAdd = (item: any) => {
        setAlert({
            message: t("Item Added")
            , severity: 'success'
            , datetime: Date.now().toString()
        });
        dispatch({ type: 'AddItem', payload: listItemTextAttributes(item) })
    }

    const handleEdit = (item: any) => {
        setAlert({
            message: t("Item Updated")
            , severity: 'success'
            , datetime: Date.now().toString()
        });
        dispatch({
            type: 'UpdateItem',
            payload: listItemTextAttributes(item),
            primarykey: Object.keys(item as any)[0],
        })
    }
    const handleDelete = (id: string, itemsArray: any[]) => {
        setAlert({
            message: t("Item Removed")
            , severity: 'success'
            , datetime: Date.now().toString()
        });
        dispatch({ type: 'RemoveItems', payload: itemsArray, primarykey: id })
    }
    const handleError = (error: string) => {
        console.error('Error fetching options', error);
        setAlert({
            message: error
            , severity: 'error'
            , datetime: Date.now().toString()
        });
        dispatch({ type: 'Error', payload: error })
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement> | null) => {

        dispatch({ type: 'UpdateFilter', payload: event === null ? '' : event.target.value })
        debouncedFetch
    };


    return (
        <>
            {(state.componentParams.showToggle === true || state.componentParams.showPieChart === true) &&
                state.Items.length > 0 &&
                <Box
                    sx={toggleBoxSx}
                >
                    <ToggleButtonGroup
                        value={state.viewMode}
                        exclusive
                        onChange={handleViewMode}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="list" aria-label="list viewMode">
                            <ReorderIcon />
                        </ToggleButton>
                        {state.componentParams.showToggle && <ToggleButton value="matrix" aria-label="matrix viewMode">
                            <AppsIcon />
                        </ToggleButton>}
                        {state.componentParams.showPieChart && <ToggleButton value="graph" aria-label="graph viewMode">
                            <BarChartIcon />
                        </ToggleButton>}

                    </ToggleButtonGroup>
                </Box>
            }

            <>
                {state.viewMode === 'list' && < CommonInfiniteScrollPaginatedList
                    loadMore={loadMore}
                    items={state.Items}
                    hasMore={state.hasMore}
                    itemType={itemType}
                    CRUDEForm={CRUDEForm}
                    CRUDEFormURL={CRUDEFormURL}
                    filter={state.filter}
                    hideAddition={hideAddition}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleError={handleError}
                    handleFilterChange={handleFilterChange}
                    page={state.page}
                    componentParams={componentParams}

                />}

                {state.viewMode === 'matrix' && <CommonImageGrid
                    items={state.Items}
                    hasMore={state.hasMore}
                    loadMore={loadMore}
                    CRUDEForm={CRUDEForm}
                    hideAddition={hideAddition}
                    handleAdd={handleAdd}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleError={handleError}
                    page={state.page}
                    componentParams={componentParams}

                />
                }

                {state.viewMode === 'graph' && <CommonPieChart
                    data={state.GraphItems}
                />
                }

            </>

        </>
    )

}

CommonInfiniteScrollList.defaultProps = {
    componentParams: { PageSize: -1, showToggle: false, filter: null, avatarType: 'images/general', showPieChart: false, pieChartNameAttr: '', pieChartValAttr: '' },
    FormURL: ''
};

export default CommonInfiniteScrollList;
