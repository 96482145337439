import { UFFormatDate } from "../../../../Common/UseForm/UFDatePicker/UFDatePicker"
import { UFFormatTime } from "../../../../Common/UseForm/UFTimePicker/UFTimePicker"
import { IMeal } from "../IMeal"

export const ListItemTextAttributes = (item: IMeal) => {
    //const { t } = useTranslation()

    return ({
        ...item,
        checked: false,
        primaryText: UFFormatDate(item.Date) + ' ' + UFFormatTime(item.Hour),
        secondaryText: `User: ${item.MealUserIDLabel} `,
    })
}

export default ListItemTextAttributes