import './_UFDatePicker.scss'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { TextField } from '@mui/material'

const DATE_FORMAT = 'YYYY/MM/DD'

export const UFFormatDate = (dateValue: any) => {
    if (dateValue !== undefined && dateValue !== '') {
        if (typeof dateValue === 'object') {
            if (typeof dateValue.format === 'function') {
                dateValue = dateValue.format('YYYY/MM/DD')
            } else {
                const year = dateValue.toLocaleString('default', { year: 'numeric' })
                const month = dateValue.toLocaleString('default', { month: '2-digit' })
                const day = dateValue.toLocaleString('default', { day: '2-digit' })
                dateValue = year + '/' + month + '/' + day
            }
        }
    } else {
        if (dateValue === '') {
            dateValue = new Date()
            const year = dateValue.toLocaleString('default', { year: 'numeric' })
            const month = dateValue.toLocaleString('default', { month: '2-digit' })
            const day = dateValue.toLocaleString('default', { day: '2-digit' })
            dateValue = year + '/' + month + '/' + day
        }
    }
    return dateValue
}

const UFDatePicker = (props: IInputProps) => {
    const { name, control, label } = props

    return (
        <Controller
            name={name}
            control={control}
            //defaultValue={((value === undefined || value === '') ? new Date() : value)}
            defaultValue={new Date()}
            render={({ field: { onChange, value } }) => (
                <div style={{ width: '100%', marginTop: '1rem' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            data-test={name}
                            label={label}
                            inputFormat={DATE_FORMAT}
                            renderInput={(params) => <TextField {...params} fullWidth={true} />}
                            onChange={(date) => {
                                onChange(date)
                            }}
                            value={value === undefined || value === '' ? new Date() : new Date(value)}
                        />
                    </LocalizationProvider>
                </div>
            )}
        />
    )
}

export default UFDatePicker
