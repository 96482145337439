import './_UFAutocomplete.scss'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { ICommon } from '../../Types/ICommon'
import { Options } from '../../Types/CommonHelper'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { AlertColor } from '@mui/material/Alert'
import { Autocomplete, Avatar, Chip, TextField } from '@mui/material'
import { Methods } from '../../../../Utils/Utils'
import { useAlert } from '../../../../contexts/AlertContext'

interface itemListState {
    items: ICommon[]
    itemsLoading: boolean
    itemsLoaded: boolean
    parentItems: number[]
    parentItemsLoading: boolean
    parentItemsLoaded: boolean
    error: boolean
    alertOpened: boolean
    alertSeverity: AlertColor
    alertMessage: string
}
const initialItemState: itemListState = {
    items: [],
    itemsLoaded: false,
    itemsLoading: true,
    parentItems: [],
    parentItemsLoaded: false,
    parentItemsLoading: true,
    error: false,
    alertOpened: false,
    alertSeverity: 'success',
    alertMessage: '',
}

type itemListActions =
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: ICommon[] }
    | { type: 'LoadParentItems' }
    | { type: 'ParentItemsLoaded'; payload: number[] }
    | { type: 'CloseAlert' }
    | { type: 'Error'; payload: string }

const ItemReducer = (state: itemListState, action: itemListActions): itemListState => {
    switch (action.type) {
        case 'LoadItems':
            return { ...state, itemsLoading: true, itemsLoaded: false }
        case 'LoadParentItems':
            return { ...state, parentItemsLoading: true, parentItemsLoaded: false }
        case 'ItemsLoaded':
            return {
                ...state,
                items: action.payload,
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
            }
        case 'ParentItemsLoaded':
            return {
                ...state,
                parentItems: action.payload,
                parentItemsLoading: false,
                parentItemsLoaded: true,
                error: false,
            }
        case 'Error':
            return {
                ...state,
                error: true,
                itemsLoading: false,
                itemsLoaded: false,
                parentItemsLoading: false,
                parentItemsLoaded: false,
            }
        case 'CloseAlert':
            return { ...state, alertOpened: false }
        default:
            return state
    }
}

export interface IInputAutocompleteProps extends IInputProps {
    setValue: any
    parentApi: string

}

const UFAutocomplete = (props: IInputAutocompleteProps) => {
    const { name, control, api, parentApi, idColName, nameColName, setValue, required, label, chipImageName } = props

    const [state, dispatch] = useReducer(ItemReducer, initialItemState)
    const [selectedItems, setSelectedItems] = useState<number[]>(state.parentItems)
    const { setAlert } = useAlert();


    const loadParentItems = useCallback(() => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''

        const abortController = new AbortController()
        const ignore = false
        const tmpURL = process.env.REACT_APP_METHODS_API + parentApi + '?ref=1'
        fetch(tmpURL, {
            method: 'GET',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                let data = await response.json()
                data = Methods.JSONUnminify(data)

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status

                    const error = (data && data.message) || response.status
                    setAlert({
                        message: error
                        , severity: 'error'
                        , datetime: Date.now().toString()
                    });
                    dispatch({ type: 'Error', payload: error })
                    return Promise.reject(error)
                }

                if (!ignore) {
                    // @ts-ignore
                    dispatch({
                        type: 'ParentItemsLoaded',
                        payload: data.map(function (item: any) {
                            // @ts-ignore
                            return item[idColName]
                        }),
                    })
                }
            })
            .catch((error) => {
                setAlert({
                    message: error
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                dispatch({ type: 'Error', payload: error })
                console.error('There was an error!', error)
            })

        //return () => { }
    }, [parentApi, idColName])

    const loadItems = useCallback(() => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''

        const abortController = new AbortController()
        const ignore = false
        const tmpURL = process.env.REACT_APP_METHODS_API + '' + api + '?ref=1'


        fetch(tmpURL, {
            method: 'GET',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                let data = await response.json()
                data = Methods.JSONUnminify(data)

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    setAlert({
                        message: error
                        , severity: 'error'
                        , datetime: Date.now().toString()
                    });
                    dispatch({ type: 'Error', payload: error })
                    return Promise.reject(error)
                }

                if (!ignore) {
                    dispatch({ type: 'ItemsLoaded', payload: data })
                    if (parentApi !== '') {
                        loadParentItems()
                    }
                }
            })
            .catch((error) => {
                setAlert({
                    message: error
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                dispatch({ type: 'Error', payload: error })
                console.error('There was an error!', error)
            })
    }, [api, loadParentItems, parentApi])

    useEffect(() => {
        loadItems()
    }, [loadItems])

    useEffect(() => {
        setValue(name, selectedItems)
    }, [selectedItems, name, setValue])

    useEffect(() => {
        setSelectedItems(state.parentItems)
    }, [state.parentItems])

    /*const idInArray = (pIDName: string, p_id: number, selectedItems: number[]): boolean => {

        let i,
            j = selectedItems.length;

        for (i = 0; i < j; i++) {
            // @ts-ignore
            if (selectedItems[i][pIDName] === p_id) {
                return true;
            }
        }
        return false;

    }*/

    const handleOnChange = (event: object, value: any) => {
        //setSelectedItems(value);

        // @ts-ignore
        //let tmpStudents = value.map(y => state.items.filter(x => x.Name === y));

        // @ts-ignore
        setSelectedItems(value.map((x) => x[idColName]))
    }

    const handleImageError = (event: any) => {

        event.target.src = '/assets/school.png';

    }
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: required,
            }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                <div style={{ width: '100%', marginTop: '1rem' }}>
                    {((parentApi !== '' && state.itemsLoaded && state.parentItemsLoaded) ||
                        (parentApi === '' && state.itemsLoaded)) && (
                            <Autocomplete
                                multiple
                                id={name}
                                onChange={handleOnChange}
                                // @ts-ignore
                                options={state.items}
                                // @ts-ignore
                                getOptionLabel={(option) => option[nameColName]}
                                // @ts-ignore
                                defaultValue={state.items.filter((x) => state.parentItems.includes(x[idColName]))}
                                freeSolo
                                // @ts-ignore
                                renderTags={(value: readonly ICommon[], getTagProps) =>
                                    //value.map((option: string, index: number) => (
                                    value.map((option: ICommon, index: number) => (
                                        //<Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        // @ts-ignore
                                        <Chip key={index} variant="outlined"
                                            // @ts-ignore
                                            label={option[nameColName]} {...getTagProps({ index })}
                                            // @ts-ignore
                                            avatar={<Avatar alt={option[nameColName]}
                                                // @ts-ignore
                                                src={option[chipImageName]} onError={handleImageError} />}

                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <>
                                        <TextField {...params} variant="filled" label={label} placeholder={label} />
                                    </>

                                )}
                            />
                        )}
                </div>
            )}
        />
    )
}

export default UFAutocomplete
