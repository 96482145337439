import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import CustomSVG from "../../CustomSVG/CustomSVG"
import { useTranslation } from "react-i18next";
interface CustomDrawerSingleLevelProps {

    item: any
    open?: boolean
    handleListItemClick?: (e: React.MouseEvent<HTMLLIElement>) => void
}


//const CustomDrawerSingleLevel = ({ item }: any) => {
const CustomDrawerSingleLevel = (props: CustomDrawerSingleLevelProps) => {
    const { item, handleListItemClick, open } = props
    const { t } = useTranslation()
    return (
        <ListItem
            key={item.MenuID}
            disablePadding
            sx={{ display: 'block' }}
            onClick={handleListItemClick}
            id={item.MenuID}
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        ml: item.ParentMenuID != null ? 2 * item.MenuLevel + 'rem' : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Tooltip title={t(item.Name)}>
                        <Box
                            sx={{
                                mr: '.5rem',
                            }}
                        >

                            <CustomSVG strSVG={item.Icon} />
                        </Box>
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t(item.Name)} />
            </ListItemButton>
        </ListItem>
    )
}
export default CustomDrawerSingleLevel