import './_UserCard.scss'
import Typography from '@mui/material/Typography';
import { IUser } from '../IUser';
import { useTranslation } from 'react-i18next'
import CustomAvatar from '../../../../Common/CustomAvatar/CustomAvatar';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Methods } from '../../../../../Utils/Utils';
import CustomSVG from '../../../../Common/CustomSVG/CustomSVG';
import { CONTACTDETAILSICON, ORGANIZATIONSICON, ROLESICON, SECURITYICON, STUDENTSICON, USERSICON } from '../../../../../styles/_icons';

interface ICardProps {
    item: IUser
    handleEditCard: (prmType: string) => void
}

interface IEditLegendProps {
    title: string
    handleEditCard: (prmType: string) => void
    type: string
}

const EditableLegend = (props: IEditLegendProps) => {
    const { handleEditCard, title, type } = props

    const handleClick = () => {
        handleEditCard(type)
    }
    return (
        <div>
            <legend style={{ display: 'flex', alignItems: 'center' }}>

                <div style={{ flex: 1, }}>
                    <Typography gutterBottom variant="h6"  >
                        {title}
                    </Typography>
                </div>
                <IconButton onClick={handleClick}>
                    <EditIcon />
                </IconButton>
            </legend>
        </div>
    );
};

const UserCard = (props: ICardProps) => {
    const { handleEditCard, item } = props
    const { t } = useTranslation()
    return (
        <div>
            <br />
            <div className='centered-avatar'>
                <CustomAvatar
                    itemType="images/user"
                    keyName="UserID"
                    item={item}
                    sizeHeight={200}
                    sizeWidth={200}
                    justViewMode={true}

                />
            </div>

            <div>

                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Name Details Information")} handleEditCard={handleEditCard} type={'namedetails'} />
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={USERSICON} />
                        </div>
                        {Methods.formatText(item.Name, 'Name') + ' ' + Methods.formatText(item.Surname1, 'Surname1') + ' ' + Methods.formatText(item.Surname2, '')}
                    </div>
                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Contact Details")} handleEditCard={handleEditCard} type={'contactdetails'} />


                    {(Methods.isPopulated(item.ContactName) || Methods.isPopulated(item.ContactNumber) || Methods.isPopulated(item.ContactEmail)) && <div className="group-container">
                        <div className="icon-container">
                            <div className='icon'>
                                <CustomSVG strSVG={CONTACTDETAILSICON} />
                            </div>
                            <div>{Methods.formatText(item.ContactName, 'Contact Name')}</div>
                        </div>
                        <div className='empty-icon'>{Methods.formatText(item.ContactNumber, 'Contact Number')}</div>
                        <div className='empty-icon'>{Methods.formatText(item.ContactEmail, 'Contact Number')}</div>
                    </div>}
                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Credential Details Information")} handleEditCard={handleEditCard} type={'credentialdetails'} />
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={SECURITYICON} />
                        </div>
                        {Methods.formatText(item.Email, 'Email')}
                    </div>
                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Role Details Information")} handleEditCard={handleEditCard} type={'roledetails'} />
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={ORGANIZATIONSICON} />
                        </div>
                        {Methods.formatText(item.OrganizationDSCR + '', 'Organization')}
                    </div>
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={ROLESICON} />
                        </div>
                        {Methods.formatText(item.RoleDSCR, 'Role')}
                    </div>

                    {/*<div className='empty-icon'>{Methods.formatText(item.Active + '', 'Active')}</div>
                    <div className='empty-icon'>{Methods.formatText(item.IsEmployee + '', 'IsEmployee')}</div>*/}
                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Student Details Information")} handleEditCard={handleEditCard} type={'studentdetails'} />
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={STUDENTSICON} />
                        </div>
                        {Methods.formatText(item.StudentsDSCR, 'Students')}
                    </div>
                </fieldset>

            </div >
        </div >
    )
}


export default UserCard