import './_UserForm.scss'
import { Mode, Operation } from '../../../../../../Common/Types/CommonType'
import { IUser } from '../../../IUser'
import { useMediaQuery } from '@mui/material'
import eskolaTheme from '../../../../../../../theme/eskolaTheme'
import { useCallback, useEffect, useReducer, useState } from 'react'
import UserFormResponsive from '../UserForm'
import { Options } from '../../../../../../Common/Types/CommonHelper'

import { IFormProps } from '../../../../../../Common/Interfaces/IFormProps'

import { Methods } from '../../../../../../../Utils/Utils'
import { ItemFormReducer, initialItemFormState } from '../../../../../../Common/Reducers/CommonFormReducer'


const UserForm = (props: IFormProps) => {
    const { mode, onCloseNoSave, onClose, id, reRender } = props
    const [state, dispatch] = useReducer(ItemFormReducer, initialItemFormState())
    const [counters, setCounters] = useState<{ [key: string]: string }>({ roles: '0' })
    const loadItem = useCallback(async () => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''
        const response = await fetch(process.env.REACT_APP_METHODS_API + 'user/' + id, Options)

        if (!response.ok) {
            dispatch({ type: 'Error', payload: `HTTP error! status: ${response.status}` })
        } else {
            const data = await response.json()
            dispatch({ type: 'ItemLoaded', payload: data[0] })
        }



        Methods.getCounters('users/' + id + '/students?count=1', 'students', dispatch)
        Methods.getCounters('users/' + id + '/tasklinks?count=1', 'tasklinks', dispatch)
        Methods.getCounters('users/' + id + '/cares?count=1', 'cares', dispatch)
        Methods.getCounters('users/' + id + '/meals?count=1', 'meals', dispatch)
        Methods.getCounters('users/' + id + '/usergroups?count=1', 'usergroups', dispatch)
    }, [id, reRender])

    useEffect(() => {
        if (mode !== Mode.Add) {
            loadItem()
        } else {
            dispatch({ type: 'ItemLoaded', payload: initialItemFormState() })
        }
    }, [loadItem, mode])

    const matches = useMediaQuery(eskolaTheme.breakpoints.up('md'))


    const handlImageUpdated = (filename: string) => {
        //dispatch({ type: 'ItemLoaded', payload: { ...state.item, ProfileImg: filename } })
        //onClose({ ...state.item, ProfileImg: filename }, Operation.SaveNotClose, '')
    }
    return (
        <>



            {state.itemLoaded && !matches && (
                <UserFormResponsive
                    item={state.item as IUser}
                    onClose={onClose}
                    onCloseNoSave={onCloseNoSave}
                    mode={mode}
                    operation={mode === Mode.Add ? Operation.Add : Operation.Edit}
                    onImageUpdated={handlImageUpdated}

                />
            )}
        </>
    )
}

export default UserForm