import React from 'react'
import { Button, Grid, Typography, Link, Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Options } from '../../../Common/Types/CommonHelper'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { useForm } from 'react-hook-form'
import { IForgotPassword } from './IForgotPassword'
import { useLoading } from '../../../../contexts/LoadingContext'
import { useAlert } from '../../../../contexts/AlertContext'
import OnboardingWrapper from '../OnboardingWrapper/OnboardingWrapper'

const ForgotPassword: React.FC = () => {

    const { t } = useTranslation()
    const methods = useForm<IForgotPassword>({ defaultValues: { email: '' } })
    const theme = createTheme()
    const { handleSubmit, control, setValue } = methods
    const [alertSeverity, setAlertSeverity] = React.useState({ status: '', msg: '' })
    const { setAlert } = useAlert();
    const { setLoading, isLoading } = useLoading();

    const handleForgotPassword = async (data: IForgotPassword) => {
        setAlertSeverity({ status: '', msg: '' })
        setLoading(true)

        try {
            const prmItem = {
                Email: data.email,
            }

            const abortController = new AbortController()

            const response = await fetch(process.env.REACT_APP_METHODS_API + 'users/forgotpassword', {

                method: 'POST',
                body: JSON.stringify(prmItem),
                signal: abortController.signal,
                headers: Options.headers,
            })

            console.log(response)
            if (!response) {
                setLoading(false)
                setAlertSeverity({ status: 'error', msg: t('Error occurred. Please try again later.') })
                setAlert({
                    message: t('Error occurred. Please try again later.')
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
            } else {
                setLoading(false)
                setAlertSeverity({ status: 'OK', msg: t('Forgot Password sent to your email!') })
                setAlert({
                    message: t('Forgot Password sent to your email!')
                    , severity: 'success'
                    , datetime: Date.now().toString()
                });
            }
        } catch (err) {
            setLoading(false)
            setAlertSeverity({ status: 'error', msg: t('Error occurred. Please try again later.') })
            setAlert({
                message: t('Error occurred. Please try again later.')
                , severity: 'error'
                , datetime: Date.now().toString()
            });
        }


    }

    return (

        <OnboardingWrapper >
            <Typography component="h1" variant="h5" align="center">
                {t('Reset Password')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit(handleForgotPassword)} sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="email"
                            label={t('Email')}
                            required={t('Empty field!')}
                            autoComplete={'email'}
                            autoFocus={true}
                            inputType={EInputType.Email}
                        />
                    </Grid>

                    {alertSeverity.status === '' && (
                        <Grid item xs={12}>
                            <Button type="submit" disabled={isLoading} variant="contained" color="primary" fullWidth>
                                {isLoading ? t('Sending password recovery email') : t('Reset Password')}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs>
                        <Typography variant="body2" color="textPrimary" align="left">
                            <Link underline="hover" href="/login">
                                {t('Home')}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </OnboardingWrapper >
    )
}

export default ForgotPassword
