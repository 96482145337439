import './_FaultForm.scss'
import { IFault } from '../IFault'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import SaveCancelToolBar from '../../../../Common/SaveCancelToolBar/SaveCancelToolBar'
import FooterToolBar from '../../../../Common/FooterToolBar/FooterToolBar'

import { useForm } from 'react-hook-form'
import React from 'react'
import CommonTransition from '../../../../Common/CommonTransition/CommonTransition'
import { Box, useTheme } from '@mui/material'
import FaultFormDetails from './Details/FaultFormDetails'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'



const FaultForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const theme = useTheme();
    const methods = useForm<IFault>({ defaultValues: props.item })
    const { handleSubmit, control, setValue, watch } = methods
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [tabValue, setTabValue] = useState('1')
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    return (
        <Dialog open={true} onClose={props.onCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
            <SaveCancelToolBar
                api="Faults"
                idName="FaultID"
                title={operation === Operation.Add ? t('Fault') : item.Date + ' ' + t('Fault')}
                item={props.item}
                mode={mode}
                operation={operation}
                onSave={onClose}
                onCloseNoSave={onCloseNoSave}
                handleSubmit={handleSubmit}
            />

            <DialogContent>
                <TabContext value={tabValue}>
                    <TabList variant="scrollable" onChange={handleTabChange} sx={theme.customSx.globalFormTabList}>
                        <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
                    </TabList>

                    <Box sx={theme.customSx.globalFormTab(tabValue === '1')}>
                        <FaultFormDetails
                            mode={mode}
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            item={item}
                            disabled={operation === Operation.Delete ? true : false}
                        />
                    </Box>
                </TabContext>
            </DialogContent>
            {mode === Mode.DeleteEdit ? (
                <FooterToolBar
                    primaryKeyName={'FaultID'}
                    primaryKeyValue={item.FaultID}
                    name={item.Date}
                    api="faults"
                    onSave={onClose}
                    item={props.item}
                />
            ) : null}
        </Dialog>
    )
}

export default FaultForm
