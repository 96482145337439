import './_MealForm.scss'
import { IMeal } from '../IMeal'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import SaveCancelToolBar from '../../../../Common/SaveCancelToolBar/SaveCancelToolBar'
import FooterToolBar from '../../../../Common/FooterToolBar/FooterToolBar'
import MealFormDetails from './Details/MealFormDetails'
import { useForm } from 'react-hook-form'
import { UFFormatDate } from '../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import { UFFormatTime } from '../../../../Common/UseForm/UFTimePicker/UFTimePicker'
import React from 'react'
import CommonTransition from '../../../../Common/CommonTransition/CommonTransition'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'

import MealFormat from '../List/ListItemTextAttributes'

import { useAuth } from '../../../../../contexts/AuthContext'
import { Box, useTheme } from '@mui/material'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'



const MealForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const theme = useTheme();
    //const [sessionContext] = useSessionContext()
    const { user } = useAuth()
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const methods = useForm<IMeal>({ defaultValues: item })
    const { handleSubmit, control, setValue, watch } = methods
    const [tabValue, setTabValue] = useState('1')
    const [studentsCountValue, setStudentsCountValue] = useState(0)
    const [MealsCountValue, setMealsCountValue] = useState('0')
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const handleStudentsCount = (count: number) => {
        setStudentsCountValue(count)
    }
    const handleListLoaded = (name: string, count: number) => {
        switch (name) {
            case 'Meal':
                setMealsCountValue(count + '')

                break
            default:
                break
        }
    }
    return (
        <Dialog open={true} onClose={props.onCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
            <SaveCancelToolBar
                api="Meals"
                idName="MealID"
                title={
                    operation === Operation.Add
                        ? t('Meal')
                        : UFFormatDate(item.Date) + ' ' + UFFormatTime(item.Hour) + ' ' + t('Meal')
                }
                item={item}
                mode={mode}
                operation={operation}
                onSave={onClose}
                onCloseNoSave={onCloseNoSave}
                handleSubmit={handleSubmit}
            />

            <DialogContent>
                <TabContext value={tabValue}>
                    <TabList variant="scrollable" onChange={handleTabChange} sx={theme.customSx.globalFormTabList}>
                        <Tab
                            label={t('Details') + ' (' + studentsCountValue.toString() + ' ' + t('StudentCount') + ')'}
                            value="1"
                            icon={<InfoIcon />}
                        />
                        <Tab label={t('Meals') + '(' + MealsCountValue + ')'} value="2" icon={<RestaurantIcon />} />
                    </TabList>

                    <Box sx={theme.customSx.globalFormTab(tabValue === '1')}>
                        <MealFormDetails
                            mode={mode}
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            item={item}
                            handleStudentsCount={handleStudentsCount}
                            disabled={operation === Operation.Delete ? true : false}
                        />
                    </Box>
                    <Box sx={theme.customSx.globalFormTab(tabValue === '2')}>
                        {/*<MealList handleListLoaded={handleListLoaded} isVisibleNow={tabValue === '2'} url={'meals'} />*/}
                        <CommonInfiniteScrollList
                            url={'organizations/' + user!.OrganizationID + '/meals'}
                            isVisibleNow={tabValue === '2'}
                            listItemTextAttributes={MealFormat}
                            CRUDEForm={MealForm}
                            hideAddition={true}
                            CRUDEFormURL='meal'
                        />
                    </Box>
                </TabContext>
            </DialogContent>
            {mode === Mode.DeleteEdit ? (
                <FooterToolBar
                    primaryKeyName={'MealID'}
                    primaryKeyValue={item.MealID}
                    name={item.Date + ' ' + item.Hour}
                    api="Meals"
                    onSave={onClose}
                    item={props.item}
                />
            ) : null}
        </Dialog>
    )
}

export default MealForm
