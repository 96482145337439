// import './_SignUp.scss'
import React from 'react'
import { createTheme } from '@mui/material/styles'
import { Button, Grid, Typography, Box, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { useForm } from 'react-hook-form'
import { ISignUp } from './ISignUp'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { useLoading } from '../../../../contexts/LoadingContext'
import { useAuth } from '../../../../contexts/AuthContext'
import { useAlert } from '../../../../contexts/AlertContext'
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { jwtDecode } from 'jwt-decode';
import OnboardingWrapper from '../OnboardingWrapper/OnboardingWrapper'

const SignUp: React.FC = () => {
    //const classes = useStyles();
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = createTheme()
    //const [session, setSession] = useSessionContext()
    const { user, setUserDetails } = useAuth()
    const { setLoading, isLoading } = useLoading();
    const methods = useForm<ISignUp>({ defaultValues: { email: '', password: '', name: '', phone: '', address: '' } })
    const { handleSubmit, control, setValue } = methods
    const { setAlert } = useAlert();


    const handleSignup = async (prm: ISignUp) => {
        try {

            if (prm.password !== prm.confirmpassword) {
                setAlert({
                    message: 'Passwords do not match.'
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                return
            }


            const prmItem = {
                Email: prm.email,
                Password: prm.password,
                Name: prm.name,
                Phone: prm.phone,
                Address: prm.address
            }

            const abortController = new AbortController()
            setLoading(true)

            const OptionsLogin = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Origin: process.env.REACT_APP_ORIGIN + '',
                },
            }

            const response = await fetch(process.env.REACT_APP_METHODS_API + 'users/signup', {
                method: 'POST',
                body: JSON.stringify(prmItem),
                signal: abortController.signal,
                headers: OptionsLogin.headers
            })

            if (!response.ok) {

                const errorData = await response.json();
                console.log(JSON.stringify(errorData))

                setLoading(false)
                setAlert({
                    message: errorData.message
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                /*setUserDetails({
                    ...user,
                    isAuthenticated: false,
                    userName: '',
                    menus: [],
                    OpUserID: -1,
                    OrganizationID: -1,
                    OrganizationDSCR: '',
                    redirectPath: '',
                    isSuperUser: false,
                    ProfileImg: '',
                })*/
            } else {
                setLoading(false)
                setAlert({
                    message: t('Check your email to activate your account')
                    , severity: 'success'
                    , datetime: Date.now().toString()
                });

                //const data = await response.json()

                /*if (typeof data.menusKeys !== 'undefined') {
                    const keyArray = data.menusKeys
                    const dataArray = data.menus
                    const tmpMenusAndKeys = { keyArray, dataArray }
                    data.menus = Methods.JSONUnminify(tmpMenusAndKeys)
                }

                localStorage.setItem('auth', data.token)
                setTimeout(() => {
                    setUserDetails({
                        ...user,
                        isAuthenticated: true,
                        userName: data.Name,
                        menus: data.menus,
                        OpUserID: data.UserID,
                        OrganizationID: data.OrganizationID,
                        OrganizationDSCR: data.OrganizationDSCR,
                        redirectPath: data.defaultDashboard,
                        isSuperUser: data.isSuperUser === 1 ? true : false,
                        ProfileImg: data.ProfileImg,
                    })
                    navigate(data.defaultDashboard)
                }, 200)*/
            }
        } catch (err) {
            //setAlertMessage(t('Error while loading'))
            //setAlertOpened(true)
            setLoading(false)
            setAlert({
                message: t('Error while loading')
                , severity: 'error'
                , datetime: Date.now().toString()
            });
            /*setUserDetails({
                ...user,
                isAuthenticated: false,
                userName: '',
                menus: [],
                OpUserID: -1,
                OrganizationID: -1,
                OrganizationDSCR: '',
                redirectPath: '',
                isSuperUser: false,
                ProfileImg: '',
            })*/
        }
    }

    const handleSuccess = (credentialResponse: CredentialResponse) => {


        if (credentialResponse && credentialResponse.credential) {
            console.log('There is some credential');
            console.log(JSON.stringify(credentialResponse));
            const decodedToken = jwtDecode<any>(credentialResponse.credential);

            alert('We are going to signup directly with Google');
            // Now you have the user's details
            console.log("Decoded Token:", decodedToken);
            console.log("User Name:", decodedToken.name);
            console.log("User Email:", decodedToken.email);
            console.log("User Picture:", decodedToken.picture);



        }
    };

    const handleError = () => {
        alert('test failed')
        console.error("Login failed");
    };



    return (
        <>
            <OnboardingWrapper >
                <Typography component="h1" variant="h5" align="center">
                    {t('Register')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit(handleSignup)} noValidate sx={{ mt: 1 }}>
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="name"
                        label={t('Name')}
                        required={t('Empty field!')}
                        autoComplete={'name'}
                        autoFocus={true}
                        inputType={EInputType.Text}
                    />

                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="Surname1"
                        label={t('Surname1')}
                        required={t('Empty field!')}
                        autoComplete={'Surname'}
                        autoFocus={true}
                        inputType={EInputType.Text}
                    />

                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="email"
                        label={t('Email')}
                        required={t('Empty field!')}
                        autoComplete={'email'}
                        autoFocus={true}
                        inputType={EInputType.Email}
                    />
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="password"
                        label={t('Password')}
                        required={t('Empty field!')}
                        autoComplete={'password'}
                        autoFocus={true}
                        inputType={EInputType.Password}
                    />
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="confirmpassword"
                        label={t('Repeat Password')}
                        required="Empty field!"
                        inputType={EInputType.Password}
                    />

                    <Button data-test="signin-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {t('Register')}
                    </Button>
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleError}
                        text='signup_with'
                    />

                    <Grid container>
                        <Grid item xs>
                            <Typography variant="body2" color="textPrimary" align="left">
                                <Link underline="none" href="/login">
                                    {t('Login')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </OnboardingWrapper >
        </>
    )
}

export default SignUp
