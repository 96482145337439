import './_CareGroupFormDetails.scss'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFAutocomplete from '../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useTranslation } from 'react-i18next'
import { ICareGroup } from '../../ICareGroup'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useAuth } from '../../../../../../contexts/AuthContext'
interface CareGroupFormDetailsProps {
    item: ICareGroup
    control: any
    disabled: boolean
    setValue: any
}

const CareGroupFormDetails = (props: CareGroupFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, item, setValue } = props
    //const [session] = useSessionContext()
    const { user } = useAuth()
    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Name"
                    label={t('Name')}
                    required="Empty field!"
                    disabled={disabled}
                />
                <UFSelect
                    setValue={setValue}
                    name="OrganizationID"
                    control={control}
                    label={t('Organization')}
                    idColName="OrganizationID"
                    nameColName="Name"
                    api={(user!.isSuperUser ? "organizations" : "organization/" + user!.OrganizationID.toString())}
                    disabled={(user!.isSuperUser ? disabled : true)}
                    required={t('Required value')}
                    value={user!.OrganizationID.toString()}
                />
                <UFSelect
                    name="CareUserID"
                    setValue={setValue}
                    control={control}
                    label={t('CareUserName')}
                    idColName="UserID"
                    nameColName="Name"
                    api={'organizations/' + user!.OrganizationID + '/users'}
                    disabled={disabled}
                    required={t('Required value')}
                />
                <UFAutocomplete
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    api={'organizations/' + user!.OrganizationID + '/students'}
                    parentApi={'caregroups/' + item.CareGroupID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />
                {/*<UFMultiSelect
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    api={'organizations/' + session.OrganizationID + '/students'}
                    parentApi={'caregroups/' + item.CareGroupID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />*/}
            </DivFlex>
        </div>
    )
}

export default CareGroupFormDetails
