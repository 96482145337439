
export const Options = {
    headers: {
        'Content-Type': 'application/json',
        'x-access-token':
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjI4N2Q5ODM3NDMyNTg1YTNiNjYyOTMwIiwiZW1haWwiOiJ6aWdvcnJldGVAdGVzdC5jb20iLCJpYXQiOjE2NTQwMjcwMTQsImV4cCI6MTY1NDAzNDIxNH0.HMQqtaIJwqP0XXWUd7LwyV6VST6_zN8g5PrzfdZ-TMA',
        Accept: 'application/json',
        Authorization: '',
        Origin: process.env.REACT_APP_ORIGIN + '',
    },
}

export const CommonInitialItemState = (prmInitialItem: any) => {
    return {
        item: prmInitialItem,
        itemLoaded: false,
        itemLoading: true,
        error: false,
        alertOpened: false,
        alertSeverity: 'success',
        alertMessage: '',
        counters: {},
    }
}
