// CoolAlert.js
import React from 'react';

import './_CommonAlert.scss';
import { useAlert } from '../../../contexts/AlertContext';


const CommonAlert = () => {

    const { alert, clearAlert } = useAlert();
    if (!alert) return null;
    if (!alert.message) return null;

    return (
        <div className={`alert-container ${alert.severity}`}>
            <div className="alert-box">
                {alert.message}
                <button className="dismiss-button" onClick={clearAlert}>&times;</button>
            </div>
        </div>
    );
};

export default CommonAlert;
