import './_CustomSVG.scss'
// CustomSVG.tsx
import React from 'react'
import { SvgIcon } from '@mui/material'


interface CustomSVGProps {
    width?: number;
    height?: number;
    strSVG: string;

}

const CustomSVG = (props: CustomSVGProps) => {


    const { width, height, strSVG } = props;

    return < SvgIcon width={width} height={height} viewBox="0 0 24 24" >
        {/*<path fill="none" d="M0 0h24v24H0z" />*/}
        <g dangerouslySetInnerHTML={{ __html: strSVG }} />
        {/* <path d={strSVG} strokeWidth="0" fill="currentColor" />*/}
    </SvgIcon >
};

export default CustomSVG;


