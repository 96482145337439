import './_Copyright.scss'
import { Typography, Link } from '@mui/material';

const Copyright = (props: any) => {

    const buildDate = process.env.REACT_APP_BUILD_DATE || 'Build date not available';
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ mt: 2 }}>
            {'Copyright © '}
            <Link underline="none" href="https://www.ask-e.net">
                www.ask-e.net
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
            <br />
            {/*{'v:' + process.env.REACT_APP_VERSION}*/}
            {'v:' + buildDate}
            <br />
        </Typography>
    )
}

export default Copyright
