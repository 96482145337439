import './_StudentCard.scss'
import Typography from '@mui/material/Typography';
import { IStudent } from '../IStudent';
import { useTranslation } from 'react-i18next'
import CustomAvatar from '../../../../Common/CustomAvatar/CustomAvatar';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Methods } from '../../../../../Utils/Utils';
import CustomSVG from '../../../../Common/CustomSVG/CustomSVG';
import { ALLERGIESICON, BIRTHDAYICON, CONTACTDETAILSICON, LEVELSICON, NOTEICON, ORGANIZATIONSICON, USERSICON } from '../../../../../styles/_icons';

interface ICardProps {
    item: IStudent
    handleEditCard: (prmType: string) => void
}

interface IEditLegendProps {
    title: string
    handleEditCard: (prmType: string) => void
    type: string
}

const EditableLegend = (props: IEditLegendProps) => {
    const { handleEditCard, title, type } = props

    const handleClick = () => {
        handleEditCard(type)
    }
    return (
        <div>
            <legend style={{ display: 'flex', alignItems: 'center' }}>

                <div style={{ flex: 1, }}>
                    <Typography gutterBottom variant="h6"  >
                        {title}
                    </Typography>
                </div>
                <IconButton onClick={handleClick}>
                    <EditIcon />
                </IconButton>
            </legend>
        </div>
    );
};

const StudentCard = (props: ICardProps) => {
    const { handleEditCard, item } = props
    const { t } = useTranslation()
    return (
        <div>
            <br />
            <div className='centered-avatar'>
                <CustomAvatar
                    itemType="images/student"
                    keyName="StudentID"
                    item={item}
                    sizeHeight={200}
                    sizeWidth={200}
                    justViewMode={true}

                />
            </div>

            <div>

                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Name Details Information")} handleEditCard={handleEditCard} type={'namedetails'} />
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={USERSICON} />
                        </div>
                        {Methods.formatText(item.Name, 'Name') + ' ' + Methods.formatText(item.Surname1, 'Surname1') + ' ' + Methods.formatText(item.Surname2, '')}
                    </div>
                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("School Information")} handleEditCard={handleEditCard} type={'schooldetails'} />

                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={ORGANIZATIONSICON} />
                        </div>
                        {Methods.formatText(item.OrganizationDSCR, 'School')}
                    </div>
                    <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={LEVELSICON} />
                        </div>
                        <div>{Methods.formatText(item.LevelDSCR, 'Level')}</div>
                    </div>


                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Contact Details")} handleEditCard={handleEditCard} type={'contactdetails'} />


                    {(Methods.isPopulated(item.ContactName1) || Methods.isPopulated(item.ContactNumber1) || Methods.isPopulated(item.ContactEmail1)) && <div className="group-container">
                        <div className="icon-container">
                            <div className='icon'>
                                <CustomSVG strSVG={CONTACTDETAILSICON} />
                            </div>
                            <div>{Methods.formatText(item.ContactName1, 'Contact Name 1')}</div>
                        </div>
                        <div className='empty-icon'>{Methods.formatText(item.ContactNumber1, 'Contact Number 1')}</div>
                        <div className='empty-icon'>{Methods.formatText(item.ContactEmail1, 'Contact Number 2')}</div>
                    </div>}


                    {(Methods.isPopulated(item.ContactName2) || Methods.isPopulated(item.ContactNumber2) || Methods.isPopulated(item.ContactEmail2)) && <div className="group-container">
                        <br />
                        <div className="icon-container">
                            <div className='icon'>
                                <CustomSVG strSVG={CONTACTDETAILSICON} />
                            </div>
                            <div>{Methods.formatText(item.ContactName2, 'Contact Name 2')}</div>
                        </div>
                        <div className='empty-icon'>{Methods.formatText(item.ContactNumber2, 'Contact Number 2')}</div>
                        <div className='empty-icon'>{Methods.formatText(item.ContactEmail2, 'Contact Email 2')}</div>
                    </div>}






                </fieldset>
                <fieldset style={{ border: '1px solid transparent', borderRadius: '4px' }}>
                    <EditableLegend title={t("Extra Information")} handleEditCard={handleEditCard} type={'extradetails'} />

                    {item.AllergiesDSCR !== '' && typeof item.AllergiesDSCR !== 'undefined' && item.AllergiesDSCR !== null && <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={ALLERGIESICON} />
                        </div>
                        <div>{item.AllergiesDSCR}</div>
                    </div>
                    }

                    {item.BirthDate !== '1900/01/01' && item.BirthDate !== '' && <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={BIRTHDAYICON} />
                        </div>
                        <div>{item.BirthDate}</div>
                    </div>
                    }

                    {item.Note !== '' && <div className="icon-container">
                        <div className='icon'>
                            <CustomSVG strSVG={NOTEICON} />
                        </div>
                        <div>{item.Note}</div>
                    </div>
                    }

                </fieldset>

            </div >
        </div >
    )
}


export default StudentCard