import './_Login.scss'

import { Button, Grid, Box, Typography, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { ILogin } from './ILogin'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { Methods } from '../../../../Utils/Utils'
import { useSocket } from '../../../../contexts/SocketContext'
import { useLoading } from '../../../../contexts/LoadingContext'
import { useAlert } from '../../../../contexts/AlertContext'
import { useAuth } from '../../../../contexts/AuthContext'
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { jwtDecode } from 'jwt-decode';
import OnboardingWrapper from '../OnboardingWrapper/OnboardingWrapper'
import { useState } from 'react'




const Login = () => {
    const navigate = useNavigate()
    const { user, setUserDetails } = useAuth();
    const [Is2FA, setIs2FA] = useState(0)
    const { t } = useTranslation()
    const methods = useForm<ILogin>({ defaultValues: { email: '', password: '', twofactorcode: '', googleAuthCode: '', googlePicture: '' } })
    const { handleSubmit, control, setValue } = methods
    const { socket } = useSocket();
    const { setAlert } = useAlert();
    const { setLoading, isLoading } = useLoading();


    const handleDifferentLogins = async (prmLogin: ILogin, prmType: string) => {
        try {

            let prmItem = {}


            const abortController = new AbortController()
            setLoading(true)

            const OptionsLogin = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Origin: process.env.REACT_APP_ORIGIN + '',
                },
            }

            let strAPI = ''
            if (prmType === 'userNamePassLogin') {
                strAPI = process.env.REACT_APP_METHODS_API + 'users/login'
                prmItem = {
                    Email: prmLogin.email,
                    Password: prmLogin.password,
                    TwoFactorCode: prmLogin.twofactorcode,
                    googleAuthCode: prmLogin.googleAuthCode
                }
            } else if (prmType === 'googleLogin') {
                strAPI = process.env.REACT_APP_METHODS_API + 'users/logingoogle'
                prmItem = {
                    Email: prmLogin.email,
                    googleAuthCode: prmLogin.googleAuthCode
                }
            }
            const response = await fetch(strAPI, {
                method: 'POST',
                body: JSON.stringify(prmItem),
                signal: abortController.signal,
                headers: OptionsLogin.headers
            })

            if (!response.ok) {

                const data = await response.json()
                setAlert({
                    message: data.msg
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });


                setLoading(false)
                setUserDetails({
                    ...user,
                    isAuthenticated: false,
                    userName: '',
                    menus: [],
                    OpUserID: -1,
                    OrganizationID: -1,
                    OrganizationDSCR: '',
                    redirectPath: '',
                    isSuperUser: false,
                    ProfileImg: '',
                })

            } else {
                const data = await response.json()

                if (data.Is2FA) {
                    localStorage.setItem('auth', data.token)
                    setIs2FA(1)
                    setLoading(false)
                } else {
                    if (typeof data.menusKeys !== 'undefined') {
                        const keyArray = data.menusKeys
                        const dataArray = data.menus
                        const tmpMenusAndKeys = { keyArray, dataArray }
                        data.menus = Methods.JSONUnminify(tmpMenusAndKeys)
                    }

                    localStorage.setItem('auth', data.token)
                    console.log('prmLogin.googlePicture:', prmLogin.googlePicture);
                    console.log('prmType:', prmType);
                    setTimeout(() => {

                        setUserDetails({
                            ...user,
                            isAuthenticated: true,
                            userName: data.Name,
                            menus: data.menus,
                            OpUserID: data.UserID,
                            OrganizationID: data.OrganizationID,
                            OrganizationDSCR: data.OrganizationDSCR,
                            redirectPath: data.defaultDashboard,
                            isSuperUser: data.isSuperUser === 1 ? true : false,
                            ProfileImg: (prmType === 'googleLogin' ? prmLogin.googlePicture : data.ProfileImg),
                        })



                        if (socket) {
                            socket.emit('register_user', data.UserID);
                        }
                        setLoading(false)
                        navigate(data.defaultDashboard)
                    }, 200)

                }


            }
        } catch (err) {

            setLoading(false)
            setAlert({
                message: t('Error while loading')
                , severity: 'error'
                , datetime: Date.now().toString()
            });

            console.error(err);

            setUserDetails({
                ...user,
                isAuthenticated: false,
                userName: '',
                menus: [],
                OpUserID: -1,
                OrganizationID: -1,
                OrganizationDSCR: '',
                redirectPath: '',
                isSuperUser: false,
                ProfileImg: '',
            })
        }
    }

    const handleLoginNew = async (prm: ILogin) => {
        handleDifferentLogins(prm, 'userNamePassLogin')
    }

    const handleResetIs2FA = () => {
        setIs2FA(0)

    }

    const handleSuccess = (credentialResponse: CredentialResponse) => {
        // Handle the response from Google and verify the token with your backend
        //console.log(credentialResponse);
        // Store user data and token in local storage or state management if needed
        if (credentialResponse && credentialResponse.credential) {
            console.log('There is some credential');
            console.log(JSON.stringify(credentialResponse));
            const decodedToken = jwtDecode<any>(credentialResponse.credential);


            // Now you have the user's details
            console.log("Decoded Token:", decodedToken);
            console.log("User Name:", decodedToken.name);
            console.log("User Email:", decodedToken.email);
            console.log("User Picture:", decodedToken.picture);


            const prmLogin: ILogin = {
                email: decodedToken.email,
                password: '',
                twofactorcode: '',
                googleAuthCode: credentialResponse.credential,
                googlePicture: decodedToken.picture,
            }
            handleDifferentLogins(prmLogin, 'googleLogin')
        }
    };

    const handleError = () => {
        alert('test failed')
        console.error("Login failed");
    };
    return (
        <>
            <OnboardingWrapper>
                <Typography component="h1" variant="h5" align="center">
                    {(Is2FA ? t('We have sent to you a 2FA code, please provide it!') : t('Welcome'))}
                </Typography>
                <Box component="form" onSubmit={handleSubmit(handleLoginNew)} noValidate sx={{ mt: 1 }}>
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="email"
                        label={t('Email')}
                        required={t('Empty field!')}
                        autoComplete={'email'}
                        autoFocus={true}
                        disabled={Is2FA === 1}
                        inputType={EInputType.Email}
                    />

                    {Is2FA === 0 && <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="password"
                        label={t('Password')}
                        required={t('Empty field!')}
                        autoComplete={'current-password'}
                        autoFocus={true}
                        inputType={EInputType.Password}
                    />}

                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="twofactorcode"
                        label={t('2FA code')}
                        required={t('Empty field!')}
                        autoFocus={true}
                        inputType={EInputType.Numeric}
                        hidden={true && Is2FA === 0}
                    />

                    <Button
                        data-test="signin-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {(Is2FA ? t('Provide 2FA Code!!') : t('Enter'))}
                    </Button>
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleError}
                    />

                    {Is2FA === 1 &&
                        <Typography variant="body2" color="textSecondary" align="center">
                            <Link underline="none" onClick={handleResetIs2FA} href="#">
                                {t('Start Login again')}
                            </Link>
                        </Typography>}

                    {Is2FA === 0 &&
                        <Grid container display="flex" justifyContent="space-between">
                            <Grid>
                                <Link href="/signup" underline="hover" variant="inherit">
                                    {t('Register')}
                                </Link>
                            </Grid>
                            <Grid>
                                <Link underline="hover" href="/forgotpassword" variant="inherit" >
                                    {t('Forgot password ?')}
                                </Link>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </OnboardingWrapper>
        </>
    )
}
export default Login
