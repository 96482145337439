// src/AuthContext.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { Session, initialSession } from '../models/session';

// Define types for user and context

type AuthContextType = {
    user: Session | null;
    setUserDetails: (details: Session | null) => void;
    //getUserDetails: () => Session;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define a helper hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

interface AuthContextProviderProps {
    children?: React.ReactNode
}


// Define the AuthProvider component

export const AuthProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const [user, setUserDetails] = useState<Session | null>(initialSession);


    useEffect(() => {
        const savedUserDetails = sessionStorage.getItem('userDetails');
        if (savedUserDetails) {
            setUserDetails(JSON.parse(savedUserDetails));
        }
    }, []);

    useEffect(() => {
        if (user?.isAuthenticated) {
            sessionStorage.setItem('userDetails', JSON.stringify(user));
        } else {
            sessionStorage.removeItem('userDetails');
        }
    }, [user]);

    /*const getUserDetails = () => {
        const tmpUserSession = sessionStorage.getItem('userDetails');
        let tmpUser = initialSession
        if (tmpUserSession !== null) {
            tmpUser = JSON.parse(tmpUserSession);
        }
        return tmpUser;
    }*/

    return (

        <AuthContext.Provider value={{ user, setUserDetails }}>
            {children}
        </AuthContext.Provider>
    );
};
