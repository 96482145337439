import { useCallback, useEffect, useReducer, useState } from 'react'
import { ICommon } from '../../Types/ICommon'
import { Options } from '../../Types/CommonHelper'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { AlertColor } from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, FormHelperText, IconButton } from '@mui/material'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import SelectorAutocomplete from '../../SelectorAutocomplete/SelectorAutocomplete'
import JustCloseToolBar from '../../JustCloseToolBar/JustCloseToolBar'
import { DivFlex } from '../../../../theme/eskolaTheme'
import { Methods } from '../../../../Utils/Utils'

interface itemListState {
    items: ICommon[]
    itemsLoading: boolean
    itemsLoaded: boolean
    parentItems: number[]
    parentItemsLoading: boolean
    parentItemsLoaded: boolean
    error: boolean
    alertOpened: boolean
    alertSeverity: AlertColor
    alertMessage: string
    moreItemsOpened: boolean
    selectedItems: number[]
}
const initialItemState: itemListState = {
    items: [],
    itemsLoaded: false,
    itemsLoading: true,
    parentItems: [],
    parentItemsLoaded: false,
    parentItemsLoading: true,
    error: false,
    alertOpened: false,
    alertSeverity: 'success',
    alertMessage: '',
    moreItemsOpened: false,
    selectedItems: [],
}

type itemListActions =
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: ICommon[] }
    | { type: 'LoadParentItems' }
    | { type: 'ParentItemsLoaded'; payload: number[] }
    | { type: 'CloseAlert' }
    | { type: 'OpenMoreItems' }
    | { type: 'CloseOpenMoreItems' }
    | { type: 'AddMoreItemsToList'; payload: ICommon[] }
    | { type: 'SetSelectedItems'; payload: number[] }
    | { type: 'Error'; payload: string }

const ItemReducer = (state: itemListState, action: itemListActions): itemListState => {
    switch (action.type) {
        case 'LoadItems':
            return { ...state, itemsLoading: true, itemsLoaded: false }
        case 'LoadParentItems':
            return { ...state, parentItemsLoading: true, parentItemsLoaded: false }
        case 'ItemsLoaded':
            return {
                ...state,
                items: action.payload,
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
            }
        case 'AddMoreItemsToList':
            return {
                ...state,
                // @ts-ignore
                items: state.items
                    .concat(action.payload)
                    .sort((a, b) => Object.values(a)[1].localeCompare(Object.values(b)[1])),
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
                moreItemsOpened: false,
                // @ts-ignore
                selectedItems: state.selectedItems.concat(
                    // @ts-ignore
                    action.payload.map(function (item: any) {
                        return Object.values(item)[0]
                    })
                ),
            }
        case 'ParentItemsLoaded':
            return {
                ...state,
                parentItems: action.payload,
                parentItemsLoading: false,
                parentItemsLoaded: true,
                error: false,
            }
        case 'SetSelectedItems':
            return {
                ...state,
                selectedItems: action.payload,
                error: false,
            }
        case 'Error':
            return {
                ...state,
                error: true,
                itemsLoading: false,
                itemsLoaded: false,
                parentItemsLoading: false,
                parentItemsLoaded: false,
            }
        case 'CloseAlert':
            return { ...state, alertOpened: false }
        case 'OpenMoreItems':
            return { ...state, moreItemsOpened: true }
        case 'CloseOpenMoreItems':
            return { ...state, moreItemsOpened: false }
        default:
            return state
    }
}

export interface IInputMultiSelectProps extends IInputProps {
    setValue: any
    parentApi: string
    selectAll?: boolean
    watch?: any
    displayMoreItemsButton?: boolean
    moreItemsApi?: string
}

const UFMultiSelect = (props: IInputMultiSelectProps) => {
    const {
        name,
        control,
        disabled,
        api,
        parentApi,
        moreItemsApi,
        idColName,
        nameColName,
        setValue,
        label,
        selectAll,
        displayMoreItemsButton,
    } = props
    const { t } = useTranslation()
    const [state, dispatch] = useReducer(ItemReducer, initialItemState)
    const [moreItems, setMoreItems] = useState<ICommon[]>([])

    const loadParentItems = useCallback(() => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''

        const abortController = new AbortController()
        const ignore = false
        const tmpURL = process.env.REACT_APP_METHODS_API + parentApi + '?ref=1'
        fetch(tmpURL, {
            method: 'GET',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                let data = await response.json()
                data = Methods.JSONUnminify(data)
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    dispatch({ type: 'Error', payload: error })
                    return Promise.reject(error)
                }

                if (!ignore) {
                    // @ts-ignore
                    dispatch({
                        type: 'ParentItemsLoaded',
                        payload: data.map(function (item: any) {
                            return Object.values(item)[0]
                        }),
                    })
                }
            })
            .catch((error) => {
                dispatch({ type: 'Error', payload: error })
                console.error('There was an error!', error)
            })
    }, [parentApi])

    const loadItems = useCallback(() => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''

        const abortController = new AbortController()
        const tmpURL = process.env.REACT_APP_METHODS_API + '' + api + '?ref=1'


        const ignore = false
        fetch(tmpURL, {
            method: 'GET',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                let data = await response.json()
                data = Methods.JSONUnminify(data)
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    dispatch({ type: 'Error', payload: error })
                    return Promise.reject(error)
                }

                if (!ignore) {
                    dispatch({ type: 'ItemsLoaded', payload: data })
                    if (selectAll === undefined || selectAll === false) {
                        loadParentItems()
                    } else {
                        // @ts-ignore
                        dispatch({
                            type: 'ParentItemsLoaded',
                            payload: data.map(function (item: any) {
                                return Object.values(item)[0]
                            }),
                        })
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: 'Error', payload: error })
                console.error('There was an error!', error)
            })
    }, [api, selectAll, loadParentItems])

    useEffect(() => {
        loadItems()
    }, [loadItems])

    useEffect(() => {
        setValue(name, state.selectedItems)
    }, [state.selectedItems, name, setValue])

    useEffect(() => {
        //setSelectedItems(state.parentItems);
        dispatch({ type: 'SetSelectedItems', payload: state.parentItems })
    }, [state.parentItems])

    const handleToggle = (value: any) => () => {
        const currentIndex = state.selectedItems.indexOf(value)
        const newItems = [...state.selectedItems]

        if (currentIndex === -1) {
            newItems.push(value)
        } else {
            newItems.splice(currentIndex, 1)
        }
        //setSelectedItems(newItems);
        dispatch({ type: 'SetSelectedItems', payload: newItems })
    }

    const handleOpenMoreItems = () => {
        dispatch({ type: 'OpenMoreItems' })
    }

    const handleCloseOpenMoreItems = () => {
        setMoreItems([])
        dispatch({ type: 'CloseOpenMoreItems' })
    }

    const handleSaveMoreItems = () => {
        dispatch({ type: 'AddMoreItemsToList', payload: moreItems })
    }

    const handleMoreItems = (item: ICommon) => {
        // @ts-ignore
        setMoreItems(item)
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                <div style={{ width: '100%' }}>
                    <FormControl fullWidth id={name}>
                        {state.items.length > 0 && (
                            <DivFlex variant="">
                                <InputLabel
                                    id="UFMultiSelect"
                                    disabled={disabled}
                                    sx={{ position: 'left', fontSize: '0.8rem', pb: '1rem', pt: '0rem' }}
                                >
                                    {t(label)}
                                </InputLabel>
                                {displayMoreItemsButton && (
                                    <IconButton
                                        size="small"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={handleOpenMoreItems}
                                        sx={{ position: 'left' }}
                                    >
                                        <FactCheckIcon />
                                    </IconButton>
                                )}
                            </DivFlex>
                        )}
                        <List
                            sx={{
                                width: '100%',
                                maxHeight: 300,
                                overflow: 'auto',
                                bgcolor: 'background.paper',
                                mb: '2rem',
                            }}
                            data-test={name}
                        >
                            {state.items.map((item: ICommon) => {
                                const labelId = `checkbox-list-label-${Object.values(item)[0]}`
                                return (
                                    <ListItem key={Object.values(item)[0]} disablePadding disabled={props.disabled}>
                                        <ListItemButton role={undefined} dense sx={{ p: '0.0rem' }}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={
                                                        // @ts-ignore
                                                        //selectedItems.indexOf(item[idColName]) !== -1

                                                        state.selectedItems.indexOf(Object.values(item)[0]) !== -1
                                                    }
                                                    tabIndex={-1}
                                                    disabled={disabled}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={handleToggle(Object.values(item)[0])}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                id={labelId}
                                                primary={
                                                    // @ts-ignore
                                                    t(item[nameColName])
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <FormHelperText>{error ? error.message : null}</FormHelperText>
                    </FormControl>

                    {state.moreItemsOpened && (
                        <Dialog open={true} onClose={handleCloseOpenMoreItems} fullScreen={true}>
                            <JustCloseToolBar
                                title={t('Add more items to list')}
                                onCloseNoSave={handleCloseOpenMoreItems}
                                handleSubmit={handleSaveMoreItems}
                            />
                            <DialogContent>
                                <SelectorAutocomplete
                                    name="moreItems"
                                    label={t('Add more items to list')}
                                    idColName={idColName}
                                    nameColName={nameColName}
                                    api={moreItemsApi}
                                    alreadyExistingItems={state.items}
                                    handleMoreItems={handleMoreItems}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </div>
            )}
        />
    )
}

export default UFMultiSelect
