import React, { useEffect, useRef, useState } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, TextField, Autocomplete, InputAdornment, IconButton, Fab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomAvatar from '../../CustomAvatar/CustomAvatar';

import { Mode, Operation } from '../../Types/CommonType';
import CancelIcon from '@mui/icons-material/Cancel'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import './_CommonInfiniteScrollPaginatedList.scss'
import AddIcon from '@mui/icons-material/Add'
import { useAlert } from '../../../../contexts/AlertContext';
import CommonForm from '../../CommonForm/CommonForm';

interface CommonInfiniteScrollPaginatedListProps {
    loadMore: () => void;
    items: any[];
    hasMore: boolean;
    itemType?: string
    CRUDEForm?: React.ComponentType<any>;
    CRUDEFormURL?: string;
    //setFilter: (value: string) => void;
    filter: string;
    hideAddition?: boolean;
    handleAdd: (item: any) => void;
    handleEdit: (item: any) => void;
    handleDelete: (id: string, itemsArray: any[]) => void;
    handleError: (error: string) => void;
    handleFilterChange: (event: any) => void;
    page: number;
    variant?: 'default' | 'other';
    componentParams?: any;
}

const CommonInfiniteScrollPaginatedList: React.FC<CommonInfiniteScrollPaginatedListProps> = (
    { loadMore, items, hasMore, itemType, CRUDEForm, CRUDEFormURL, filter, hideAddition,
        handleAdd, handleEdit, handleDelete, handleError, handleFilterChange, page, variant = 'default', componentParams

    }) => {
    const { t } = useTranslation()

    const [selectedID, setSelectedID] = useState<number>(0);
    const observerRef = useRef<HTMLLIElement>(null);
    const [formOpeningMode, setFormOpeningMode] = useState<Mode>(Mode.None)
    const { setAlert } = useAlert();
    const [reRenderForm, setReRenderForm] = useState<boolean>(false)
    const [isGettingData, setIsGettingData] = useState<boolean>(true);


    const autoCompleteBoxSx = (componentParams.showToggle || componentParams.showPieChart) ? {
        width: '100%',
        position: 'fixed',
        top: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
        zIndex: 1099,
        padding: '20px 20px',
        '@media (max-width: 600px)': {
            top: '110px',

        },
    } : {
        width: '100%',
        position: 'fixed',
        top: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        zIndex: 1099,
        padding: '20px 20px',
        '@media (max-width: 600px)': {
            top: '55px',

        },
    }

    const autocompleteSx = {
        width: '100%',
        height: '50px', // Adjust the height as needed for the entire component
        '& .MuiAutocomplete-inputRoot': {
            height: '100%',
            '& .MuiInputBase-input': {
                height: '100%', // Ensure the input field covers the entire height
                padding: '16px 14px', // Adjust padding as needed
            },
        },
    };

    const sxList = (componentParams.showToggle || componentParams.showPieChart) ? {
        position: 'relative',
        backgroundColor: 'white',
        padding: '20px',
        width: '100%',
        top: componentParams.filter ? '130px' : '55px',
    } : {
        position: 'relative',
        backgroundColor: 'white',
        padding: '20px',
        width: '100%',
        top: componentParams.filter ? '70px' : '0px',
    }


    //const { setLoading, isLoading } = useLoading();
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry.isIntersecting && hasMore && !isGettingData) {
            setIsGettingData(true);
            loadMore();

        }
    };




    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMore, isGettingData, loadMore]);

    useEffect(() => {

        setIsGettingData(false);



    }, [items]);





    useEffect(() => {
        loadMore
    }, [filter]);

    const handleOpenFormEdit = (id: string) => {


        setFormOpeningMode(Mode.DeleteEdit)
        setSelectedID(parseInt(id))
    };

    const handleOpenAdd = (event: React.FormEvent<HTMLButtonElement>) => {

        event.stopPropagation()
        setFormOpeningMode(Mode.Add)
        //dispatch({ type: 'CreateItem' })
    }


    const handlImageUpdated = (filename: string, id?: number) => {
        console.log('handlImageUpdated', filename, id)
    }

    function handleSave<T>(item: T, afterOp: Operation, error?: string): void {
        const tmp = item as unknown as T

        //setLoading(false)
        if (error) {
            handleError(error)
        } else {

            switch (afterOp) {
                case Operation.Add:
                    setFormOpeningMode(Mode.None)
                    setReRenderForm(!reRenderForm)
                    handleAdd(tmp)

                    break
                case Operation.Edit: {

                    setFormOpeningMode(Mode.None)
                    setReRenderForm(!reRenderForm)
                    handleEdit(tmp)

                    break
                }

                case Operation.Delete: {
                    setFormOpeningMode(Mode.None)
                    const tmpArra = [] as any
                    tmpArra.push(Object.values(tmp as any)[0])
                    handleDelete(Object.keys(tmp as any)[0], tmpArra)

                    break
                }

            }
        }
    }

    const handleNoSave = () => {

        setFormOpeningMode(Mode.None)
    }




    return (

        <>


            {
                componentParams.filter &&
                <Box sx={autoCompleteBoxSx}>
                    <Autocomplete
                        sx={autocompleteSx}
                        freeSolo
                        className='common-autocomplete'
                        options={
                            items.map((itm) => itm["Name"])

                        }
                        inputValue={filter}
                        onInputChange={(event, newInputValue) => {
                            handleFilterChange(event)
                        }}
                        renderInput={(params) => (

                            <TextField
                                {...params}

                                label={t("Filter")}
                                onChange={handleFilterChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onMouseUp={() => {
                                                    //setFilter('')
                                                    handleFilterChange(null as any)
                                                }}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                {(filter === '' ? null : <CancelIcon />)}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FilterAltIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                </Box>
            }



            <List sx={sxList}>

                {items.map((item: any, index) => (
                    <ListItem key={index}
                        onClick={() => handleOpenFormEdit(item[Object.keys(item)[0]])}
                        divider
                        sx={{
                            cursor: 'pointer',
                            userSelect: 'none'
                        }}

                    >

                        {typeof item.ProfileImg !== 'undefined' &&
                            <ListItemAvatar>
                                <CustomAvatar
                                    item={item}
                                    keyName={Object.keys(item)[0]}
                                    itemType={componentParams.avatarType ? componentParams.avatarType : 'images/general'}
                                    onImageUpdated={handlImageUpdated}
                                    sizeWidth={50}
                                    sizeHeight={50}
                                    justViewMode={true}
                                />
                            </ListItemAvatar>
                        }

                        <ListItemText
                            sx={{ wordBreak: 'break-all', whiteSpace: 'normal', overflowWrap: 'break-word' }}
                            primary={t(item.primaryText as string)}
                            primaryTypographyProps={{
                                fontSize: 22,
                                color: 'primary.main',
                            }}
                            secondary={item.newSecondaryText}
                            secondaryTypographyProps={{
                                fontSize: 15,
                                color: 'secondary.main',
                            }}


                        />

                    </ListItem>
                ))}
                <li ref={observerRef} style={{ height: '10px' }}></li>
                {(typeof hideAddition === 'undefined' || hideAddition === false) && <Fab sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16
                }} variant="extended" color="primary" onClick={(event) => handleOpenAdd!(event)}>
                    <AddIcon sx={{
                        position: 'relative',
                        mr: 1,


                    }} />
                    {t('Add')}
                </Fab>}
            </List>

            {/*<CRUDEForm
                    mode={formOpeningMode}
                    id={selectedID}
                    onClose={handleSave}
                    onCloseNoSave={handleNoSave}
                    reRender={reRenderForm}
            />*/}


            {
                formOpeningMode !== Mode.None && CRUDEForm &&
                <CommonForm
                    mode={formOpeningMode}
                    id={selectedID}
                    onClose={handleSave}
                    onCloseNoSave={handleNoSave}
                    reRender={reRenderForm}
                    Form={CRUDEForm}
                    FormURL={(typeof CRUDEFormURL === 'undefined' ? '' : CRUDEFormURL)}
                />
            }



            {page === -1 && items.length === 0 && <>{t('No Values')}</>}
            {page === -666 && items.length === 0 && <>{t('Session Expired')}</>}



        </>


    );
};

CommonInfiniteScrollPaginatedList.defaultProps = {
    componentParams: { PageSize: -1, showToggle: false, filter: null, avatarType: 'images/general', showPieChart: false, pieChartNameAttr: '', pieChartValAttr: '' }

};


export default CommonInfiniteScrollPaginatedList;
