import { IMenu } from '../components/Views/Administration/Menu/IMenu'

export type Session = {
    isAuthenticated?: boolean
    isSuperUser?: boolean
    redirectPath: string
    userName: string
    OpUserID: number
    menus: IMenu[]
    OrganizationID: number
    OrganizationDSCR: string
    ProfileImg?: string
}

export const initialSession: Session = {
    isAuthenticated: false,
    isSuperUser: false,
    redirectPath: '',
    userName: '',
    OpUserID: -1,
    menus: [],
    OrganizationID: -1,
    OrganizationDSCR: '',
    ProfileImg: '',
}
