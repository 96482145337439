import './_CustomDrawerLanguagesMenu.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language';
import DoneIcon from '@mui/icons-material/Done';
import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip'
interface CustomDrawerLanguagesMenuProps {
    alertOpened?: boolean

}

const CustomDrawerLanguagesMenu = (props: CustomDrawerLanguagesMenuProps) => {
    const { t, i18n } = useTranslation()
    const [anchorLanguage, setAnchorLanguage] = useState<null | HTMLElement>(null)
    const [open, setOpen] = useState(false)
    const [openRight, setOpenRight] = useState(false)
    const listLanguages = [
        { code: 'eu', label: t('Basque') },
        { code: 'es', label: t('Spanish') },
        { code: 'en', label: t('English') },
    ]

    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorLanguage(event.currentTarget)
        event.stopPropagation()
    }

    const handleLanguageMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorLanguage(null)
        event.stopPropagation()
    }


    const handleLanguageMenuAction = (action: string, event: React.MouseEvent<HTMLElement>) => {
        // Perform action on the whole list
        i18n.changeLanguage(action)
        handleLanguageMenuClose(event)
    }

    const handleGetLanguageLabel = (languageCode: string) => {
        const languageItem = listLanguages.find((languageItem) => languageItem.code === languageCode)
        if (typeof languageItem !== 'undefined') {
            return t(languageItem.label)
        } else {
            return t('English')
        }
    }


    return (
        <>

            {typeof anchorLanguage !== 'undefined' && anchorLanguage !== null && listLanguages.length > 0 && (


                <Menu anchorEl={anchorLanguage} open={Boolean(anchorLanguage)} onClose={handleLanguageMenuClose}>
                    {listLanguages!.map(
                        (languageItem) =>
                            <MenuItem
                                key={languageItem.code}
                                onClick={(event: React.MouseEvent<HTMLElement>) => handleLanguageMenuAction(languageItem.code, event)}
                            >
                                {languageItem.code === i18n.language && <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >

                                    <DoneIcon />
                                </ListItemIcon>}
                                {languageItem.label}
                            </MenuItem>

                    )}

                </Menu>

            )}

            <Divider />
            <ListItem
                key={'currentLanguage'}
                disablePadding
                sx={{ display: 'block' }}
                onClick={handleLanguageMenuOpen}
                id={'currentLanguage'}
            >
                <ListItemButton

                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >

                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Tooltip title={t('Language')}>
                            <LanguageIcon />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={handleGetLanguageLabel(i18n.language)} />
                </ListItemButton>
            </ListItem>
        </>
    )
}
export default CustomDrawerLanguagesMenu
