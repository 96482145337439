import './_SelectorAutocomplete.scss'
import { useCallback, useEffect, useReducer } from 'react'
import { ICommon } from '../Types/ICommon'
import { Options } from '../Types/CommonHelper'
import { AlertColor } from '@mui/material/Alert'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { Methods } from '../../../Utils/Utils'
interface itemListState {
    items: ICommon[]
    itemsLoading: boolean
    itemsLoaded: boolean
    parentItems: number[]
    parentItemsLoading: boolean
    parentItemsLoaded: boolean
    error: boolean
    alertOpened: boolean
    alertSeverity: AlertColor
    alertMessage: string
}
const initialItemState: itemListState = {
    items: [],
    itemsLoaded: false,
    itemsLoading: true,
    parentItems: [],
    parentItemsLoaded: false,
    parentItemsLoading: true,
    error: false,
    alertOpened: false,
    alertSeverity: 'success',
    alertMessage: '',
}

type itemListActions =
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: ICommon[] }
    | { type: 'LoadParentItems' }
    | { type: 'ParentItemsLoaded'; payload: number[] }
    | { type: 'CloseAlert' }
    | { type: 'Error'; payload: string }

const ItemReducer = (state: itemListState, action: itemListActions): itemListState => {
    switch (action.type) {
        case 'LoadItems':
            return { ...state, itemsLoading: true, itemsLoaded: false }
        case 'LoadParentItems':
            return { ...state, parentItemsLoading: true, parentItemsLoaded: false }
        case 'ItemsLoaded':
            return {
                ...state,
                items: action.payload,
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
            }
        case 'ParentItemsLoaded':
            return {
                ...state,
                parentItems: action.payload,
                parentItemsLoading: false,
                parentItemsLoaded: true,
                error: false,
            }
        case 'Error':
            return {
                ...state,
                error: true,
                itemsLoading: false,
                itemsLoaded: false,
                parentItemsLoading: false,
                parentItemsLoaded: false,
            }
        case 'CloseAlert':
            return { ...state, alertOpened: false }
        default:
            return state
    }
}

export interface ISelectorAutocompleteProps {
    name: string
    label: string
    api?: string
    idColName?: string
    nameColName?: string
    alreadyExistingItems: ICommon[]
    handleMoreItems: (par: ICommon) => void | null
}

const SelectorAutocomplete = (props: ISelectorAutocompleteProps) => {
    const { name, api, idColName, nameColName, label, handleMoreItems, alreadyExistingItems } = props

    const [state, dispatch] = useReducer(ItemReducer, initialItemState)
    //const [selectedItems, setSelectedItems] = useState<ICommon[]>([]);

    const loadItems = useCallback(() => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''
        let tmpURL = process.env.REACT_APP_METHODS_API + ''
        //tmpURL = tmpURL + (typeof api === 'undefined' ? '' : api) + '/1'
        tmpURL = tmpURL + api
        const abortController = new AbortController()
        const ignore = false
        fetch(tmpURL, {
            method: 'GET',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                let data = await response.json()
                data = Methods.JSONUnminify(data)
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    dispatch({ type: 'Error', payload: error })
                    return Promise.reject(error)
                }

                if (!ignore) {
                    // @ts-ignore
                    dispatch({
                        type: 'ItemsLoaded',
                        payload: data.filter(
                            // @ts-ignore
                            (a) => !alreadyExistingItems.map((b) => b[idColName]).includes(a[idColName])
                        ),
                    })
                }
            })
            .catch((error) => {
                dispatch({ type: 'Error', payload: error })
                console.error('There was an error!', error)
            })
    }, [api, alreadyExistingItems, idColName])

    useEffect(() => {
        loadItems()
    }, [loadItems])

    /*useEffect(() => {
        setValue(name, selectedItems);
    }, [selectedItems]);*/

    const handleOnChange = (event: object, value: any) => {
        // @ts-ignore
        //setSelectedItems(value)

        handleMoreItems(value)
    }

    return (
        <div style={{ width: '100%', marginTop: '1rem' }}>
            {state.itemsLoaded && (
                <Autocomplete
                    multiple
                    id={name}
                    onChange={handleOnChange}
                    // @ts-ignore
                    options={state.items}
                    // @ts-ignore
                    getOptionLabel={(option) => option[nameColName]}
                    // @ts-ignore
                    defaultValue={state.items.filter((x) => state.parentItems.includes(x[idColName]))}
                    freeSolo
                    // @ts-ignore
                    renderTags={(value: readonly ICommon[], getTagProps) =>
                        //value.map((option: string, index: number) => (
                        value.map((option: ICommon, index: number) => (
                            //<Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            // @ts-ignore
                            <Chip key={index} variant="outlined" label={option[nameColName]} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} variant="filled" label={label} placeholder={label} />
                    )}
                />
            )}
        </div>
    )
}

export default SelectorAutocomplete
