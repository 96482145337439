import './_StudentFormSchoolDetails.scss'
import { IStudent } from '../../../IStudent'
import UFSelect from '../../../../../../Common/UseForm/UFSelect/UFSelect'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { useTranslation } from 'react-i18next'


interface StudentFormSchoolDetailsProps {
    item: IStudent
    control: any
    setValue: any
    disabled: boolean

}


const StudentFormSchoolDetails = (props: StudentFormSchoolDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">


                <UFSelect
                    setValue={setValue}
                    name="OrganizationID"
                    control={control}
                    label={t('Organization')}
                    idColName="OrganizationID"
                    nameColName="Name"
                    api="organizations"
                    disabled={disabled}
                    required={t('Required value')}
                />
                <UFSelect
                    setValue={setValue}
                    name="LevelID"
                    control={control}
                    label={t('Level')}
                    idColName="LevelID"
                    nameColName="Name"
                    api="levels"
                    disabled={disabled}
                    required={t('Required value')}
                />



            </DivFlex>
        </div>
    )
}

export default StudentFormSchoolDetails
