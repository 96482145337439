
import React, { Suspense, useEffect, useState } from 'react';
//import { useSession } from './contexts/SessionContextNew';
import { useAuth } from './contexts/AuthContext';

import { Route, Routes } from 'react-router'

import ForgotPassword from './components/Views/Onboarding/ForgotPassword/ForgotPassword';

import SignUp from './components/Views/Onboarding/Signup/SignUp';
import Login from './components/Views/Onboarding/Login/Login';
import NotDefined from './components/Views/Administration/NotDefined/NotDefined';
import { ProtectedRouteProps } from './Auth/ProtectedRoute';
import { IMenu } from './components/Views/Administration/Menu/IMenu';

import CustomDrawerMain from './components/Common/CustomDrawer/CustomDrawerMain/CustomDrawerMain';
import { useLoading } from './contexts/LoadingContext';
import SignupConfirm from './components/Views/Onboarding/SignupConfirm/SignupConfirm';
import ResetPassword from './components/Views/Onboarding/ResetPassword/ResetPassword';


export type MethodType<T> = (param: T) => { Name: string };

export type MethodsType = {
    [key: string]: MethodType<any>;
};




const MainApp: React.FC = () => {
    //const { loggedIn, sessionUser } = useSession();


    //const user = getUserDetails();


    //const [sessionContext, updateSessionContext] = useSessionContext()
    const { user } = useAuth()
    const [components, setComponents] = useState<React.ComponentType<any>[]>([]);
    const [formComponents, setFormComponents] = useState<React.ComponentType<any>[]>([]);
    const [methods, setMethods] = useState<MethodsType>({});
    const [creatingMenuStructure, setCreatingMenuStructure] = useState<boolean>(true); // Load

    const { setLoading } = useLoading();

    const setRedirectPath = (path: string) => {
        //updateSessionContext({ ...sessionContext, redirectPath: path })
    }

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated: !!user!.isAuthenticated,
        authenticationPath: '/login',
        redirectPath: user!.redirectPath,
        setRedirectPath: setRedirectPath,
    }


    const getAllMenus = (menus: IMenu[]): IMenu[] => {
        const tmpMenus: IMenu[] = [];
        for (const item of menus) {
            tmpMenus.push(item);
            if (item.children) {
                tmpMenus.push(...getAllMenus(item.children));
            }
        }
        return tmpMenus;
    };



    useEffect(() => {




        const importComponentsAndMethods = async () => {



            if (user!.menus.length === 0) {
                setLoading(false)
                return;
            } else {

                setLoading(true)
            }

            const importedComponents: React.ComponentType<any>[] = [];
            const importedFormComponents: React.ComponentType<any>[] = [];
            const importedMethods: MethodsType = {};
            const allMenus = getAllMenus(user!.menus)
            for (const item of allMenus as IMenu[]) {
                //for (const item of routes as ConfigItem[]) {


                const componentModule = await import(`./${item.Component}`);
                importedComponents.push(componentModule.default);

                const formComponentModule = await import(`./${item.FormComponent}`);
                importedFormComponents.push(formComponentModule.default);

                const methodModule = await import(`./${item.MethodsFile}`);
                importedMethods[item.MenuID] = methodModule[item.MethodName as keyof typeof methodModule];

            }

            setComponents(importedComponents);
            setFormComponents(importedFormComponents);
            setMethods(importedMethods);
            setCreatingMenuStructure(false);
            setLoading(false)
        };

        importComponentsAndMethods();
    }, [user!.menus]);


    const prepareParams = (item: IMenu) => {
        const defaultParams = { PageSize: -1, showToggle: false, showPieChart: false, pieChartNameAttr: '', pieChartValAttr: '' }
        if (typeof item.RouteParams !== 'undefined') {
            const tmpParams = JSON.parse(item.RouteParams + '');

            if (tmpParams === null) {
                return defaultParams
            } else {
                if (tmpParams.PageSize === undefined) {
                    tmpParams.PageSize = -1;
                }
                if (tmpParams.showToggle === undefined) {
                    tmpParams.showToggle = false;
                }
                if (tmpParams.showPieChart === undefined) {
                    tmpParams.showPieChart = false;
                }
                if (tmpParams.pieChartNameAttr === undefined) {
                    tmpParams.pieChartNameAttr = '';
                }
                if (tmpParams.pieChartValAttr === undefined) {
                    tmpParams.pieChartValAttr = '';
                }

            }


            return tmpParams;
        } else {
            return defaultParams
        }

    }

    const itemParam = {};
    const renderRoutes = () => {


        const allMenus = getAllMenus(user!.menus)
        const tmpReturn = allMenus.map((item, index) => {

            const Component = components[index];
            const FormComponent = formComponents[index] as React.ComponentType<any>;
            const method = methods[item.MenuID] as MethodType<typeof itemParam>;

            return (

                <Route key={index} path={item.Route} element={


                    <Component
                        pageSize={prepareParams(item).PageSize}
                        url={item.URL.replace('{OrganizationID}', user!.OrganizationID.toString()).replace('{UserID}', user!.OpUserID.toString())}
                        listItemTextAttributes={method}
                        CRUDEForm={FormComponent}
                        CRUDEFormURL={item.FormComponentURL}
                        componentParams={prepareParams(item)}
                    />
                }


                />

            );
        });
        return tmpReturn;
    };





    if (!user!.isAuthenticated) {
        return (
            // Redirect to login page or show unauthorized message
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="signupconfirm/:token" element={<SignupConfirm />} />

                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route path="resetpassword/:token" element={<ResetPassword />} />
                <Route path="login" element={<Login />} />
                {/*<Route path="*" element={<Navigate to="/nodefined" />} />*/}
                <Route path="*" element={<NotDefined />} />
            </Routes>
        );

    } else {

        if (creatingMenuStructure) {
            return <div></div>;
        }

        return (
            <Suspense fallback={<div></div>}>
                <CustomDrawerMain>
                    <Routes>
                        <Route path="*" element={<NotDefined />} />
                        {renderRoutes()}
                    </Routes>
                </CustomDrawerMain>
            </Suspense>
        );
    }


};

export default MainApp;
