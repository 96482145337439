import './_CustomAvatar.scss'

import React from 'react'
import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Options } from '../Types/CommonHelper'
import { Avatar, Menu, MenuItem, Input, ListItemIcon, Dialog, AppBar } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { ICommon } from '../Types/ICommon'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import PreviewIcon from '@mui/icons-material/Preview'
import DeleteIcon from '@mui/icons-material/Delete'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'


import InfoIcon from '@mui/icons-material/Info';
import CommonTransition from '../CommonTransition/CommonTransition'
import { useAuth } from '../../../contexts/AuthContext'

interface CustomAvatarProps {
    item: ICommon
    itemType: string
    keyName: string
    onImageUpdated?: (filename: string, id?: number) => void
    sizeHeight: number
    sizeWidth: number
    justViewMode?: boolean
    noPreview?: boolean
    onClick?: () => void
    hasIcon?: boolean
}

const CustomAvatar: React.FC<CustomAvatarProps> = (props: CustomAvatarProps) => {
    const { t } = useTranslation()
    const { item, itemType, keyName, sizeHeight, sizeWidth, justViewMode, noPreview, hasIcon } = props
    const itemID = Object.entries(item).find(([key, value]) => key === keyName)![1]
    const fileInput = useRef<HTMLInputElement>()
    const [anchorList, setAnchorList] = useState<null | HTMLElement>(null)
    const [uploading, setUploading] = useState<boolean>(false)

    const { user } = useAuth()
    const [previewImage, setPreviewImage] = useState<boolean>(false)
    const handleListMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (justViewMode) {
            if (typeof noPreview !== 'undefined') {
                if (!noPreview) {
                    setPreviewImage(true)
                    event.stopPropagation()
                }
            } else {
                if (typeof noPreview === 'undefined') {
                    setPreviewImage(true)
                    event.stopPropagation()
                } else {
                    if (typeof props.onClick != 'undefined') {
                        props.onClick()
                    }
                }
            }
        } else {
            setAnchorList(event.currentTarget)
            event.stopPropagation()
        }


    }

    const handleListMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorList(null)
        event.stopPropagation()
    }

    const handleListMenuAction = (action: string, event: React.MouseEvent<HTMLElement>) => {
        // Perform action on the whole list
        switch (action) {
            case 'viewProfileImage':

                setPreviewImage(true)

                break
            case 'uploadImage':
                fileInput.current && fileInput.current.click()
                break
            case 'deleteImage':
                handleDelete()
                break
            default:
                console.log(`Sorry, we are out of ${action}.`)
        }

        handleListMenuClose(event)
    }
    const handleProfileImage = (filename: string, type: string) => {
        if (filename === null || typeof filename === 'undefined' || filename === '') {
            return 'genericImage.png'
        } else {
            const fileExtension = filename.split('.').pop()
            filename = filename.replace('.' + fileExtension, (type === '' ? '' : '_') + type + '.' + fileExtension)

            return filename
        }
    }
    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0]
        handleUpload(file)
    }

    const handleUpload = async (file: any) => {
        if (file) {
            const abortController = new AbortController()
            const formData = new FormData()
            //NOTE!! IF WE PASS THE IMAGE FIRST IT WONT TAKE THE PARAMETERS!!!
            formData.append('id', itemID + '')
            formData.append('type', itemType)
            formData.append('OpUserID', user!.OpUserID + '')
            formData.append('userName', user!.userName + '')
            formData.append('OrganizationID', user!.OrganizationID + '')
            formData.append('file', file)
            setUploading(true)
            try {
                const response = await fetch(process.env.REACT_APP_METHODS_API + 'uploadImage', {
                    method: 'POST',
                    body: formData,
                    headers: {
                        enctype: 'multipart/form-data',
                    },
                    signal: abortController.signal,
                })

                if (response.ok) {
                    const data = await response.json()
                    // Do something on success
                    props.onImageUpdated!(data.ProfileImg)
                    setUploading(false)
                } else {
                    console.error('File upload failed')
                    // Handle error
                    setUploading(false)
                }
            } catch (error) {
                console.error('An error occurred during file upload', error)
                // Handle error
                setUploading(false)
            }
        }
    }

    const handleDelete = async () => {
        const abortController = new AbortController()
        const prmItem: any = {}

        prmItem.id = itemID + ''
        prmItem.type = itemType
        prmItem.OpUserID = user!.OpUserID + ''
        prmItem.userName = user!.userName + ''
        prmItem.OrganizationID = user!.OrganizationID + ''
        setUploading(true)
        try {
            const response = await fetch(process.env.REACT_APP_METHODS_API + 'deleteImage', {
                method: 'POST',
                body: JSON.stringify(prmItem),
                signal: abortController.signal,
                headers: Options.headers,
            })

            if (response.ok) {
                const data = await response.json()
                // Do something on success
                props.onImageUpdated!('')
                setUploading(false)
            } else {
                console.error('File delete failed')
                // Handle error
                setUploading(false)
            }
        } catch (error) {
            console.error('An error occurred during file delete', error)
            // Handle error
            setUploading(false)
        }
    }

    const listActions = [
        { action: 'viewProfileImage', label: t('View Profile Image') },
        { action: 'uploadImage', label: t('Upload Image') },
        { action: 'deleteImage', label: t('Delete profile image') },
    ]

    const getIcon = (action: string) => {
        switch (action) {
            case 'viewProfileImage':
                return <PreviewIcon />
            case 'uploadImage':
                return <CloudUploadIcon />
            case 'deleteImage':
                return <DeleteIcon />
            default:
                return <CloudUploadIcon />
        }
    }

    const handleCloseModal = () => {
        setPreviewImage(false)
    }

    const handleImageError = (event: any) => {

        //const img = (Math.floor(Math.random() * event.target.alt) + 1).toString() + '.jpeg';
        //event.target.src = '/assets/school.png';


        const img = event.target.alt.slice(-1) + (itemType === 'images/organization' || itemType === 'images/general' ? '.webp' : '.jpeg');
        console.log('itemType', itemType);


        event.target.src = '/assets/' + itemType + '/' + img;



    }

    console.log('item.ProfileImg inside customavatar:', item.ProfileImg);
    return (
        <>
            <Input
                inputRef={fileInput}
                type="file"
                inputProps={{ accept: 'image/*' }}
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
            />



            {!uploading && (
                <div className="common-avatar">
                    <Avatar
                        onClick={(event) => handleListMenuOpen(event)}
                        onError={handleImageError}
                        sx={{
                            width: sizeWidth,
                            height: sizeHeight,
                            cursor: 'pointer',
                            filter:
                                process.env.REACT_APP_API_URL +
                                    '/' +
                                    process.env.REACT_APP_UPLOADS_FOLDER +
                                    '/' +
                                    itemType +
                                    '/' +
                                    itemID +
                                    '/' +
                                    handleProfileImage(item.ProfileImg + '', 'thumb')
                                    ? 'none'
                                    : 'blur(10px)',
                        }}

                    >
                        <LazyLoadImage
                            id="lazyLoadImage"
                            alt={itemID.toString()}
                            height={sizeHeight}
                            src={
                                (item.ProfileImg?.substring(0, 4) === 'http' ? item.ProfileImg :
                                    process.env.REACT_APP_API_URL +
                                    '/' +
                                    process.env.REACT_APP_UPLOADS_FOLDER +
                                    '/' +
                                    itemType +
                                    '/' +
                                    itemID +
                                    '/' +
                                    handleProfileImage(item.ProfileImg + '', 'thumb')
                                ) || '/assets/images/genericImage.png'

                            } // use normal <img> attributes as props
                            width={sizeWidth}
                            effect="blur"
                        />
                        {hasIcon && <IconButton
                            style={{ position: 'absolute', bottom: -10, right: 0, zIndex: 1000 }}
                        >
                            <InfoIcon />
                        </IconButton>}
                    </Avatar>
                </div>
            )}

            {typeof listActions !== 'undefined' && listActions.length > 0 && (
                <Menu anchorEl={anchorList} open={Boolean(anchorList)} onClose={handleListMenuClose}>
                    {listActions!.map(
                        (actionItem) =>
                            ((actionItem.action === 'deleteImage' &&
                                item.ProfileImg !== '' &&
                                item.ProfileImg !== null &&
                                item.ProfileImg !== undefined) ||
                                actionItem.action !== 'deleteImage') && (
                                <MenuItem
                                    key={actionItem.action}
                                    onClick={(event) => handleListMenuAction(actionItem.action, event)}
                                >
                                    <ListItemIcon>{getIcon(actionItem.action)}</ListItemIcon>

                                    {actionItem.label}
                                </MenuItem>
                            )
                    )}
                </Menu>
            )}

            <Dialog open={previewImage} onClose={handleCloseModal} fullScreen={true} TransitionComponent={CommonTransition}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleCloseModal}
                            sx={{ mr: 2 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {t('Preview profile image')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <div className='common-preview'>
                        <LazyLoadImage
                            alt={itemID.toString()}
                            onError={handleImageError}
                            src={
                                (item.ProfileImg?.substring(0, 4) === 'http' ? item.ProfileImg :
                                    process.env.REACT_APP_API_URL +
                                    '/' +
                                    process.env.REACT_APP_UPLOADS_FOLDER +
                                    '/' +
                                    itemType +
                                    '/' +
                                    itemID +
                                    '/' +
                                    handleProfileImage(item.ProfileImg + '', 'thumb')
                                ) || '/assets/images/genericImage.png'
                            }
                            effect="blur"
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CustomAvatar
