import './_UserGroupFormDetails.scss'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFAutocomplete from '../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useTranslation } from 'react-i18next'
import { IUserGroup } from '../../IUserGroup'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useAuth } from '../../../../../../contexts/AuthContext'
interface UserGroupFormDetailsProps {
    item: IUserGroup
    control: any
    disabled: boolean
    setValue: any
}

const UserGroupFormDetails = (props: UserGroupFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, item, setValue } = props
    //const [user] = useuserContext()
    const { user } = useAuth()
    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Name"
                    label={t('Name')}
                    required="Empty field!"
                    disabled={disabled}
                />
                <UFSelect
                    setValue={setValue}
                    name="OrganizationID"
                    control={control}
                    label={t('Organization')}
                    idColName="OrganizationID"
                    nameColName="Name"
                    api={(user!.isSuperUser ? "organizations" : "organization/" + user!.OrganizationID.toString())}
                    disabled={(user!.isSuperUser ? disabled : true)}
                    required={t('Required value')}
                    value={user!.OrganizationID.toString()}
                />
                <UFSelect
                    name="MainUserID"
                    setValue={setValue}
                    control={control}
                    label={t('MainUserName')}
                    idColName="UserID"
                    nameColName="Name"
                    api={'organizations/' + user!.OrganizationID + '/users?ref=1'}
                    disabled={disabled}
                    required={t('Required value')}
                />
                <UFAutocomplete
                    name="Users"
                    control={control}
                    setValue={setValue}
                    label={t('Users')}
                    idColName="UserID"
                    nameColName="Name"
                    api={'organizations/' + user!.OrganizationID + '/users'}
                    parentApi={'usergroups/' + item.UserGroupID + '/users'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />

            </DivFlex>
        </div>
    )
}

export default UserGroupFormDetails
