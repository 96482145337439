import './_FooterToolBar.scss'
import { Toolbar } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { Options } from '../Types/CommonHelper'
import { Operation } from '../Types/CommonType'
import { ICommon } from '../Types/ICommon'
import { useEffect, useState } from 'react'
import CommonConfirmation from '../CommonConfirmation/CommonConfirmation'
import { useAuth } from '../../../contexts/AuthContext'
import CommonActionsPanel from '../CommonActionsPanel/CommonActionsPanel'
import CommonDialog from '../CommonDialog/CommonDialog'

type Handler = <T>(item: T, afterOp: Operation, error?: string) => void

interface FooterToolBarProps {
    api: string
    name: string
    item: ICommon
    primaryKeyValue: number
    primaryKeyName: string
    onSave: Handler //so we can close the modal
    hideDelete?: boolean
    actions?: { label: string; onClick: () => void }[]
    openDialog?: boolean
    handleCloseDialog?: () => void
    dialogComponent?: React.ReactElement<any, any>;
    dialogExecuteDelete?: boolean;
}

const FooterToolBar = (props: FooterToolBarProps) => {
    const { name, primaryKeyValue, primaryKeyName, api, onSave, item, hideDelete, actions, openDialog, handleCloseDialog, dialogComponent, dialogExecuteDelete } = props
    const { t } = useTranslation()
    //const [session] = useSessionContext()
    const { user } = useAuth()
    const [openConfirmation, setopenConfirmation] = useState(false)

    useEffect(() => {
        if (dialogExecuteDelete) {
            setopenConfirmation(dialogExecuteDelete)
        }
    }, [dialogExecuteDelete])



    const handleDisplayConfirm = () => {
        setopenConfirmation(true)
    }
    const handleConfirm = () => {
        handleDelete()
        setopenConfirmation(false)
    }

    const handleCancel = () => {
        setopenConfirmation(false)
    }

    const handleDelete = () => {
        // @ts-ignore
        const tmpID = item[primaryKeyName]

        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''

        const tmpURL =
            process.env.REACT_APP_METHODS_API +
            api +
            '/' +
            tmpID +
            '/' +
            user!.userName +
            '/' +
            user!.OpUserID +
            '/' +
            user!.OrganizationID
        const abortController = new AbortController()

        fetch(tmpURL, {
            method: 'DELETE',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                const data = await response.json()

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    onSave(item, Operation.Delete, error)
                    return Promise.reject(error)
                }

                onSave(data, Operation.Delete)
            })
            .catch((error) => {
                onSave(item, Operation.Delete, error)
                console.error('There was an error!', error)
            })
    }







    return (
        <>
            {actions && actions.length > 0 && <Toolbar
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#eee' }}
            >
                {/*{(!actions || (actions && actions.length === 0)) && <AuditList
                    Title={t('Audit for') + ' ' + name}
                    PrimaryKeyName={primaryKeyName}
                    PrimaryKeyValue={primaryKeyValue}
                />}*/}

                {actions && actions.length > 0 && <CommonActionsPanel actions={actions} />}

                {/*{user!.isSuperUser && (typeof hideDelete === 'undefined' || hideDelete === false) && (
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        //onClick={handleDelete}
                        onClick={handleDisplayConfirm}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}*/}
            </Toolbar>}

            {openConfirmation && <CommonConfirmation
                openConfirmation={openConfirmation}
                message={t('Are you sure you want to delete the item?')}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />}

            {dialogComponent != null && handleCloseDialog && <CommonDialog
                open={openDialog!}
                onClose={handleCloseDialog}
                title="Dialog Title"
            >
                {dialogComponent}


            </CommonDialog>}

        </>
    )
}
export default FooterToolBar
