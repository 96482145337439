import './_CancelToolBar.scss'
import CloseIcon from '@mui/icons-material/Close'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'

interface CancelToolBarProps {
    title: string
    onCloseNoSave: () => void
}

const CancelToolBar = (props: CancelToolBarProps) => {
    const { title, onCloseNoSave } = props

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onCloseNoSave}
                    sx={{ mr: 2 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>

            </Toolbar>
        </AppBar>
    )
}
export default CancelToolBar
