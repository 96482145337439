
import { ICommonItemListState } from '../Interfaces/ICommonItemListState'
interface ItemFormState<T> extends ICommonItemListState {
    item: T

}

export const initialItemFormState = <T,>(): ItemFormState<T> => ({
    item: {} as T,
    itemLoaded: false,
    itemLoading: true,
    error: false,
    counters: {},
})

type ItemFormActions<T> =
    | { type: 'LoadItem' }
    | { type: 'ItemLoaded'; payload: T }
    | { type: 'Error'; payload: string }
    | { type: 'CountersLoaded'; payload: any }


function replaceNullWithDefault<T>(obj: T, defaultValue: any = -1): T {
    const result: any = { ...obj };

    for (const key in result) {
        if (typeof result[key] === 'number' && result[key] === null) {
            result[key] = defaultValue;
        }
    }

    return result as T;
}

export const ItemFormReducer = <T,>(state: ItemFormState<T>, action: ItemFormActions<T>): ItemFormState<T> => {
    switch (action.type) {
        case 'LoadItem': {
            return { ...state, itemLoading: true, itemLoaded: false }

        }
        case 'ItemLoaded': {
            console.log(action.payload)



            return {
                ...state,
                item: replaceNullWithDefault(action.payload, -1),
                itemLoading: false,
                itemLoaded: true,
                error: false,
            }
        }
        case 'Error':
            return {
                ...state,
                error: true,
                itemLoading: false,
                itemLoaded: false,

            }
        case 'CountersLoaded':
            {
                const updatedCounters = { ...state.counters }
                for (const [key, value] of Object.entries(action.payload)) {
                    updatedCounters[key] = value as number
                }
                return {
                    ...state,
                    counters: updatedCounters,
                }
            }
        default:
            return state
    }
}
