import './_UFSelect.scss'
import { useCallback, useEffect, useReducer } from 'react'
import { ICommon } from '../../Types/ICommon'
import { Options } from '../../Types/CommonHelper'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { AlertColor } from '@mui/material/Alert'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'

import { FormHelperText } from '@mui/material'
import { Methods } from '../../../../Utils/Utils'
import { useAuth } from '../../../../contexts/AuthContext'

interface itemListState {
    items: ICommon[]
    itemsLoading: boolean
    itemsLoaded: boolean
    error: boolean
    alertOpened: boolean
    alertSeverity: AlertColor
    alertMessage: string
}
const initialItemState: itemListState = {
    items: [],
    itemsLoaded: false,
    itemsLoading: true,
    error: false,
    alertOpened: false,
    alertSeverity: 'success',
    alertMessage: '',
}

type itemListActions =
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: ICommon[] }
    | { type: 'CloseAlert' }
    | { type: 'Error'; payload: string }

const ItemReducer = (state: itemListState, action: itemListActions): itemListState => {
    switch (action.type) {
        case 'LoadItems':
            return { ...state, itemsLoading: true, itemsLoaded: false }
        case 'ItemsLoaded':
            return {
                ...state,
                items: action.payload,
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
            }
        case 'Error':
            return {
                ...state,
                error: true,
                itemsLoading: false,
                itemsLoaded: false,
            }
        case 'CloseAlert':
            return { ...state, alertOpened: false }
        default:
            return state
    }
}

const handleAddNewElementToList = (event: any) => {
    console.log('handleAddNewElementToList')
    event.stopPropagation()
}


const UFSelect = (props: IInputProps) => {
    const {
        name,
        control,
        label,
        disabled,
        api,
        hideNone,
        idColName,
        nameColName,
        required,
        value,
        dontTranslate,
        setValue,
        handleRefreshIfJustOneElement,
        items
    } = props
    const [state, dispatch] = useReducer(ItemReducer, initialItemState)
    const { t } = useTranslation()
    //const [session] = useSessionContext()
    const { user } = useAuth()

    useEffect(() => {
        if (items) {
            dispatch({ type: 'ItemsLoaded', payload: items })
        } else {
            loadItems()
        }
    }, [items])


    const loadItems = useCallback(() => {
        Options.headers['x-access-token'] = localStorage.getItem('auth') + ''
        let tmpURL = '';
        //In case we have a custom api (sample bring just one organization)
        if (api!.indexOf('/') > -1) {
            tmpURL = process.env.REACT_APP_METHODS_API + '' + api
        } else {
            tmpURL = process.env.REACT_APP_METHODS_API + '' + api + '?ref=1'
        }


        const abortController = new AbortController()
        const ignore = false
        fetch(tmpURL, {
            method: 'GET',
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                let data = await response.json()

                data = Methods.JSONUnminify(data)
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    dispatch({ type: 'Error', payload: error })
                    return Promise.reject(error)
                }

                if (!ignore) {
                    dispatch({ type: 'ItemsLoaded', payload: data })
                }
            })
            .catch((error) => {
                //dispatch({ type: 'Error', payload: error })
                dispatch({ type: 'ItemsLoaded', payload: [] })
                console.error('There was an error!', error)
            })
    }, [api])

    /*useEffect(() => {
        loadItems()
    }, [loadItems])*/

    useEffect(() => {
        if (state.itemsLoaded) {
            if (state.items.length === 1) {
                if (handleRefreshIfJustOneElement !== undefined) {
                    handleRefreshIfJustOneElement!()
                }
            }
        }
    }, [state.itemsLoaded, state.items, handleRefreshIfJustOneElement])

    return (
        <>
            {state.itemsLoaded && (
                <Controller
                    name={name + 'Label'}
                    control={control}
                    render={({ field: { value }, formState: { defaultValues } }) => (
                        <input type="hidden" value={value} />
                    )}
                />
            )}

            {state.itemsLoaded && (
                <Controller
                    name={name}
                    defaultValue={
                        value === undefined || value === ''
                            ? state.items.length === 1
                                ? Object.values(state.items[0])[0]
                                : '-1'
                            : value
                    }
                    control={control}
                    rules={{
                        required: required,
                        validate: {
                            positive: (v) => isNaN(v) || parseInt(v) > 0 || t('Invalid Value'),
                        },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                        <div style={{ width: '100%', marginTop: '1rem' }}>

                            {state.error && <span>Error: {state.error}</span>}
                            {state.itemsLoaded && (
                                <FormControl id={name} fullWidth>
                                    <InputLabel id="demo-simple-select-label" disabled={disabled}>
                                        {t(label)}
                                    </InputLabel>
                                    <Select
                                        data-test={name}
                                        name={name}

                                        value={
                                            value === undefined || value === '' || value === null
                                                ? state.items.length === 1
                                                    ? Object.values(state.items[0])[0]
                                                    : '-1'
                                                : value.toString().indexOf('###') > -1
                                                    ? value.toString().split('###')[0]
                                                    : value
                                        }
                                        label={t(label)}
                                        disabled={disabled}
                                        onChange={(event: any) => {
                                            onChange(event)

                                            // @ts-ignore
                                            const tmpVal = state.items
                                                .map((element, index) => {
                                                    // @ts-ignore
                                                    if (element[idColName] === event?.target.value) {
                                                        // @ts-ignore
                                                        return element.Name
                                                    } else {
                                                        return []
                                                    }
                                                })
                                                .filter((element) => element !== undefined)[0]
                                            setValue(name + 'Label', tmpVal)
                                        }}
                                    >
                                        {(hideNone === undefined || hideNone === false) &&
                                            (state.items.length > 1 || state.items.length === 0) && (
                                                <MenuItem value="-1" key="-1">
                                                    {' '}
                                                    <em>{t('None')}</em>{' '}
                                                    {/*<Button variant="contained" onMouseDown={handleAddNewElementToList}>{t('Add')}</Button>*/}
                                                </MenuItem>
                                            )}

                                        {dontTranslate &&
                                            state.items.map((item: ICommon) => (
                                                // @ts-ignore
                                                <MenuItem key={item[idColName]} value={item[idColName]}>
                                                    {
                                                        // @ts-ignore
                                                        item[nameColName]
                                                    }
                                                </MenuItem>
                                            ))}

                                        {(!dontTranslate || dontTranslate === undefined) &&
                                            state.items.map((item: ICommon) => (
                                                // @ts-ignore
                                                <MenuItem key={item[idColName]} value={item[idColName]}>
                                                    {
                                                        // @ts-ignore
                                                        t(item[nameColName])
                                                    }
                                                </MenuItem>
                                            ))}
                                    </Select>

                                    <FormHelperText>{error ? error.message : null}</FormHelperText>
                                </FormControl>
                            )}
                        </div >
                    )}
                />
            )}
        </>
    )
}

export default UFSelect
