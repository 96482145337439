import { useMediaQuery } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import i18next from 'i18next'
import { useTheme } from '@mui/material/styles'
import { Options } from '../components/Common/Types/CommonHelper'
import { useTranslation } from 'react-i18next'

export default class Utils {
    static capFL = (str: string) => {
        return str !== undefined ? str.charAt(0).toUpperCase() + str.slice(1) : ''
    }

    static renderTranslate = (params: GridCellParams) => <>{this.capFL(i18next.t(params.value as string))}</>

    static translateValue = (params: string) => <>{i18next.t(params)}</>
}


type RecordType = { [key: string]: any }; // Generic type for input records
type ResultType = { name: string; value: number }; // Type for the resulting array    
export class Methods {
    static isMobile = () => {
        const theme = useTheme()
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
        return process.env.REACT_APP_FORCE_MOBILE ? true : isMobile
    }

    static isPopulated = (prmValue: string) => {
        if (typeof prmValue === 'undefined') {
            return false
        }
        if (prmValue === null) {
            return false
        }
        if (prmValue === '') {
            return false
        }
        return true
    }

    static formatText = (prmValue: string | undefined | null, prmLabel: string) => {
        const { t, i18n } = useTranslation()

        if (typeof (prmValue) === 'undefined' || prmValue === '' || prmValue === null) {
            if (prmLabel === '') {
                return ''
            } else {
                switch (i18n.language) {
                    case 'eu':
                        return t(prmLabel) + ' ' + t('Unknown')
                        break;
                    case 'es':
                        return t(prmLabel) + ' ' + t('Unknown')
                        break;
                    case 'en':
                        return t('Unknown') + ' ' + t(prmLabel)
                        break;
                    default:
                        return t('Unknown') + ' ' + t(prmLabel)
                        break;
                }
            }
        } else {

            return prmValue
        }
    }
    static forDate = (prm: string) => {
        try {
            const tmp = prm.substring(0, 10)
            return tmp === '1900-01-01' ? '' : prm.substring(0, 10)
        } catch (error) {
            return ''
        }
    }

    static forDateTime = (prm: string) => {
        try {
            return prm.substring(0, 16)
        } catch (error) {
            return ''
        }
    }

    static forDateTimeYYYYMMDDHH24MI = (prm: string) => {
        try {
            const date = new Date(prm);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        } catch (error) {
            return ''
        }
    }


    static JSONUnminify = (data: any) => {
        if (typeof data.keyArray === 'undefined') {
            return data
        }

        interface DataObject {
            [key: string]: any
            children?: DataObject[]
        }

        const replaceValuesWithLabelsRecursive = (labels: string[], values: (DataObject | any[])[]): DataObject[] => {
            const replaceValues = (obj: DataObject | any[]): DataObject | any[] => {
                if (Array.isArray(obj)) {
                    return obj.map((item) => replaceValues(item))
                } else if (typeof obj === 'object' && obj !== null) {
                    const replacedObj: DataObject = {}

                    for (const key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            const value = obj[key]
                            //@ts-ignore
                            const label = labels[key] || key // Use the label if available, otherwise use the key
                            replacedObj[label] = replaceValues(value)
                        }
                    }

                    return replacedObj
                } else {
                    return obj
                }
            }
            if (!Array.isArray(values)) {
                throw new Error('Expected an array')
            }
            return values.map((item) => replaceValues(item))
        }

        const replacedArray = replaceValuesWithLabelsRecursive(data.keyArray, data.dataArray)

        return JSON.parse(JSON.stringify(replacedArray))
    }

    static getCounters = async (prmURL: string, prmName: string, prmDispatch: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_METHODS_API + prmURL, Options)
            if (!response.ok) {
                prmDispatch({ type: 'Error', payload: `HTTP error! status: ${response.status}` })
            } else {
                const data = await response.json()
                const jsonObject: { [key: string]: string } = {};
                jsonObject[prmName] = data[0].count;

                prmDispatch({ type: 'CountersLoaded', payload: jsonObject })

            }
        } catch (error) {
            prmDispatch({ type: 'Error', payload: `HTTP error! status: ${error}` })
        }
    }

    static findInJSON = (data: any, value: any, key = 'title', sub = 'children', tempObj = {}) => {
        if (value && data) {
            data.find((node: any) => {
                if (node[key] == value) {
                    // @ts-ignore
                    tempObj.found = node
                    return node
                }
                return Methods.findInJSON(node[sub], value, key, sub, tempObj)
            })
            // @ts-ignore
            if (tempObj.found) {
                // @ts-ignore
                return tempObj.found
            }
        }
        return false
    }
    static hasChildren = (item: any) => {
        const { children } = item

        if (children === undefined) {
            return false
        }

        if (children.constructor !== Array) {
            return false
        }

        if (children.length === 0) {
            return false
        }

        return true
    }

    static debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
        let timeout: ReturnType<typeof setTimeout>;
        return (...args: Parameters<T>) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    }

    static generateId = () => {
        /*const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }*/
        return Math.floor(Math.random() * (10 ** 10 - 10 ** 9) + 10 ** 9);
    }

    static playSound = (sound: string) => {
        const audio = new Audio('../assets/sounds/notification.wav')
        audio.play()
    }

    static capitalizeFirstLetters = (str: string) => {
        return str.split(/(\s|-|\/)/).map(word =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join('');
    };



    static aggregateByAttribute = (
        jsonArray: RecordType[],
        nameAttr: string,
        valueAttr: string
    ): ResultType[] => {
        const result: ResultType[] = [];

        // Create a Map to store the aggregated values
        const map = new Map<string, number>();

        // Iterate over the array
        jsonArray.forEach((record) => {
            const name: string = record[nameAttr];

            const value: number = (valueAttr === '' ? 1 : record[valueAttr]);

            // If the name already exists, add the value, otherwise set it
            if (map.has(name)) {
                map.set(name, map.get(name)! + value);
            } else {
                map.set(name, value);
            }
        });

        // Convert the map to the result array
        map.forEach((value, name) => {
            result.push({ name: name, value: value });
        });

        return result;
    };



    static async fetchCountersData(url: string): Promise<{ [key: string]: string }> {
        const response = await fetch(process.env.REACT_APP_METHODS_API + url, Options);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const data = await response.json();
            console.log('data', data);
            const jsonObject: { [key: string]: string } = {};
            const key = url.split('/').pop()?.replace('?count=1', '') + '';
            jsonObject[key] = data[0].count;
            return jsonObject;
        }
        //return response.json();
    }

    static async getCountersBulk(urls: string[]): Promise<{ [key: string]: string }> {
        try {

            const fetchPromises = urls.map(url => Methods.fetchCountersData(url));


            const responses = await Promise.all(fetchPromises);

            console.log('responses', responses);
            const counters = responses.reduce((acc, response) => {
                return { ...acc, ...response };
            }, {});
            console.log('counters', counters);
            return counters;
        } catch (error) {
            console.error('Failed to get counters data', error);
            throw error; // Re-throw the error to handle it in the calling function if needed
        }
    }

    static async checkAuth(prmEmail: string) {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                return { authenticated: false };
            }

            const prmItem = {
                Email: prmEmail
            }



            const response = await fetch(process.env.REACT_APP_METHODS_API + 'users/getuserdetails', {
                method: 'POST',
                body: JSON.stringify(prmItem),
                headers: Options.headers,
            });
            if (!response.ok) {
                return { authenticated: false };
            } else {
                const data = await response.json();
                console.log('data', data);
                const jsonObject: { [key: string]: string } = {};
                return { authenticated: true, userDetails: data };

            }


        } catch (error) {
            return { authenticated: false };
        }
    }

}
