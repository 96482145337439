import { useState, FocusEvent } from 'react'
import { Controller } from 'react-hook-form'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { IInputProps, EInputType } from '../../Interfaces/IInputProps'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import CancelIcon from '@mui/icons-material/Cancel'
// import './_UFTextField.scss'


const UFTextField = (props: IInputProps) => {
    const { name, control, label, required, disabled, inputType, fullWidth, autoComplete, autoFocus, lines, setValue, hidden, placeholder, sx } =
        props
    const { t } = useTranslation()
    const [showPassword, setShowPassword] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const displayStyle = hidden !== undefined && hidden ? { display: 'none' } : { display: 'block' };

    const combinedSx = {
        ...sx,
        ...displayStyle,
    };
    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }

    const handleClickClearText = () => {
        setValue(name, '')
        console.log('handleClickClearText', name)
    }
    const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true)
    }

    const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.stopPropagation()
        setIsFocused(false)
    }





    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: !hidden && required,
                ...(inputType === EInputType.Email
                    ? {
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t('Invalid email address'),
                        },
                    }
                    : {}),
                ...(inputType === EInputType.Password
                    ? {
                        pattern: {
                            value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                            message: t(
                                'Password should contain at least one number and one special character (min 6 chars, Max 16 chars)'
                            ),
                        },
                    }
                    : {}),
                /*...(inputType === EInputType.Numeric ? {
                    pattern: {
                        value: /^[1-9]\d*(\d+)?$/i,
                        message: t('Please enter an integer'),
                    },
                } : {})*/
            }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                
                    <TextField
                        data-test={name}
                        sx={combinedSx}
                        helperText={error ? error.message : null}
                        type={
                            inputType === EInputType.Numeric
                                ? 'number'
                                : inputType === EInputType.Email
                                    ? 'email'
                                    : inputType === EInputType.Password
                                        ? showPassword
                                            ? 'text'
                                            : 'password'
                                        : 'text'
                        }
                        error={!!error}
                        onChange={onChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        value={(value === null || value === undefined) ? '' : value}
                        fullWidth={fullWidth}
                        disabled={disabled}

                        label={label}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        placeholder={(placeholder !== undefined && placeholder === '') ? '' : placeholder}
                        multiline={lines !== undefined ? true : false}
                        maxRows={lines !== undefined ? lines : 1}
                        InputProps={{
                            endAdornment:
                                (control._getWatch(name) !== null && typeof control._getWatch(name) !== 'undefined' && control._getWatch(name).length > 0) &&
                                    inputType === EInputType.Password ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleShowPassword}
                                            onMouseDown={handleShowPassword}
                                            tabIndex={-1}>
                                            {typeof showPassword === undefined ? (
                                                false
                                            ) : showPassword ? (
                                                <VisibilityIcon />
                                            ) : (
                                                <VisibilityOffIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    (control._getWatch(name) !== null && typeof control._getWatch(name) !== 'undefined' && control._getWatch(name).length > 0) &&
                                    !disabled &&
                                    isFocused && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                tabIndex={-1}
                                                onMouseDown={(e) => e.preventDefault()} // Prevents the input from losing focus
                                                onMouseUp={handleClickClearText}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                ),

                            startAdornment: inputType === EInputType.Email ? (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ) : inputType === EInputType.Telephone ? (
                                <InputAdornment position="start">
                                    <PhoneIcon />
                                </InputAdornment>
                            ) : inputType === EInputType.Address ? (
                                <InputAdornment position="start">
                                    <LocationCityIcon />
                                </InputAdornment>
                            ) : null,
                        }}
                    />
                
            )}
        />
    )
}

export default UFTextField
