import './_MealGroupForm.scss'
import { IMealGroup } from '../IMealGroup'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import SaveCancelToolBar from '../../../../Common/SaveCancelToolBar/SaveCancelToolBar'
import FooterToolBar from '../../../../Common/FooterToolBar/FooterToolBar'
import MealGroupFormDetails from './Details/MealGroupFormDetails'
import { useForm } from 'react-hook-form'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import React from 'react'
import CommonTransition from '../../../../Common/CommonTransition/CommonTransition'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
//import StudentForm from '../../../Students/Student/Form/Regular/Main/StudentForm'
import StudentFormat from '../../../Students/Student/List/ListItemTextAttributes'

import MealFormat from '../../Meal/List/ListItemTextAttributes'
import { Box, useTheme } from '@mui/material'
import { useAlert } from '../../../../../contexts/AlertContext'
import { Methods } from '../../../../../Utils/Utils'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import StudentForm from '../../../Students/Student/Form/Regular/StudentForm'
import MealForm from '../../Meal/Form/MealForm'


const MealGroupForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const methods = useForm<IMealGroup>({ defaultValues: props.item })
    const { handleSubmit, control, setValue } = methods
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [tabValue, setTabValue] = useState('1')
    const [studentsCountValue, setStudentsCountValue] = useState<string>('')
    const [MealsCountValue, setMealsCountValue] = useState<string>('')
    const theme = useTheme();

    const { setAlert } = useAlert();
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0', meals: '0' })
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    useEffect(() => {
        const getCountersData = async () => {
            const urls = [
                'mealgroups/' + item.MealGroupID + '/students?count=1',
                'mealgroups/' + item.MealGroupID + '/meals?count=1',
            ];

            try {
                const countersData = await Methods.getCountersBulk(urls);

                setCounters(prevCounters => ({
                    ...prevCounters,
                    ...countersData
                }));
            } catch (error) {
                setAlert({
                    message: t('Error')
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });

            }
        };
        if (mode === Mode.DeleteEdit) {
            getCountersData();
        }
    }, []);

    return (
        <Dialog open={true} onClose={props.onCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
            <SaveCancelToolBar
                api="Mealgroups"
                idName="MealGroupID"
                title={operation === Operation.Add ? t('Meal Group') : t(item.Name) + ' ' + t('Meal Group')}
                item={props.item}
                mode={mode}
                operation={operation}
                onSave={onClose}
                onCloseNoSave={onCloseNoSave}
                handleSubmit={handleSubmit}
            />

            <DialogContent>
                <TabContext value={tabValue}>
                    <TabList variant="scrollable" onChange={handleTabChange} sx={theme.customSx.globalFormTabList}>
                        <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
                        {mode === Mode.DeleteEdit && parseInt(counters.students) > 0 && (
                            <Tab
                                label={t('Students') + '(' + counters.students + ')'}
                                value="2"
                                icon={<AccountBoxIcon />}
                            />
                        )}
                        {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                            <Tab label={t('Meals') + '(' + counters.meals + ')'} value="3" icon={<AccountBoxIcon />} />
                        )}
                    </TabList>

                    <Box sx={theme.customSx.globalFormTab(tabValue === '1')}>
                        <MealGroupFormDetails
                            item={item}
                            control={control}
                            setValue={setValue}
                            disabled={operation === Operation.Delete ? true : false}
                        />
                    </Box>
                    {mode === Mode.DeleteEdit && (
                        <Box sx={theme.customSx.globalFormTab(tabValue === '2')}>
                            {/*<StudentList
                                handleListLoaded={handleListLoaded}
                                isVisibleNow={tabValue === '2'}
                                url={'mealgroups/' + item.MealGroupID + '/students'}
                    />*/}
                            <CommonInfiniteScrollList
                                url={'mealgroups/' + item.MealGroupID + '/students'}
                                isVisibleNow={tabValue === '2'}
                                listItemTextAttributes={StudentFormat}
                                CRUDEForm={StudentForm}
                                hideAddition={true}
                                componentParams={{ avatarType: 'images/student' }}
                                CRUDEFormURL='student'
                            />
                        </Box>
                    )}
                    {mode === Mode.DeleteEdit && (
                        <Box sx={theme.customSx.globalFormTab(tabValue === '3')}>
                            {/*<MealList
                                handleListLoaded={handleListLoaded}
                                isVisibleNow={tabValue === '3'}
                                url={'mealgroups/' + item.MealGroupID + '/meals'}
                    />*/}
                            <CommonInfiniteScrollList
                                url={'mealgroups/' + item.MealGroupID + '/meals'}
                                isVisibleNow={tabValue === '3'}
                                listItemTextAttributes={MealFormat}
                                CRUDEForm={MealForm}
                                hideAddition={true}
                                CRUDEFormURL='meal'
                            />
                        </Box>
                    )}
                </TabContext>
            </DialogContent>
            <FooterToolBar
                primaryKeyName={'MealGroupID'}
                primaryKeyValue={item.MealGroupID}
                name={item.Name}
                api="Mealgroups"
                onSave={onClose}
                item={props.item}
            />
        </Dialog>
    )
}

export default MealGroupForm
