// ErrorBoundary.tsx
import React, { Component, ReactNode } from 'react';
import { useAlert } from '../../../contexts/AlertContext';


interface Props {
    children: ReactNode;
    showAlert: (message: string, severity: 'error' | 'warning' | 'info' | 'success') => void;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

class CommonErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
        this.props.showAlert('An unexpected error occurred: ' + error.message, 'error');
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <button onClick={() => window.location.reload()}>Reload</button>
                </div>
            );
        }

        return this.props.children;
    }
}

const CommonErrorBoundaryWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { showAlert } = useAlert();

    return <CommonErrorBoundary showAlert={showAlert}>{children}</CommonErrorBoundary>;
};

export default CommonErrorBoundaryWrapper;
