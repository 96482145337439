import './_UserFormCredentialDetails.scss'
import UFTextField from '../../../../../../Common/UseForm/UFTextField/UFTextField'
import { useTranslation } from 'react-i18next'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { IUser } from '../../../IUser'

import { EInputType } from '../../../../../../Common/Interfaces/IInputProps'
import { useAuth } from '../../../../../../../contexts/AuthContext'

interface UserFormCredentialDetailsProps {
    control: any
    disabled: boolean
    setValue: any
    item: IUser

}

const UserFormCredentialDetails = (props: UserFormCredentialDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    //const [user] = useuserContext()
    const { user } = useAuth()


    return (
        <div style={{ width: '100%', marginBottom: '3rem' }}>
            <DivFlex variant="">

                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Email"
                    label={t('Email')}
                    required={t('Empty field!')}
                    disabled={disabled}
                    inputType={EInputType.Email}
                />
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Password"
                    label={t('Password')}
                    required="Empty field!"
                    disabled={disabled}
                    inputType={EInputType.Password}
                />



            </DivFlex>
        </div>
    )
}

export default UserFormCredentialDetails
