import './_UserForm.scss'
import { IUser } from '../../IUser'
import { useState } from 'react'
import { Mode, Operation } from '../../../../../Common/Types/CommonType'
import UserFormEditor from './Editor/UserFormEditor'
import UserFormAdd from './Add/UserFormAdd'

interface UserFormProps {
    item: IUser
    mode: Mode
    operation: Operation
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    onCloseNoSave: () => void
    onImageUpdated: (filename: string) => void



}

const UserForm = (props: UserFormProps) => {
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [editMode, setEditMode] = useState<string>('')
    const [apiValue, setApiValue] = useState<string>('')
    const [counters, setCounters] = useState<{ [key: string]: string }>({ roles: '0' })

    const handlImageUpdated = (filename: string) => {
        props.onImageUpdated(filename)
    }
    const handleEditCard = (prmType: string) => {

        switch (prmType) {
            case 'namedetails':
                setApiValue('users/namedetails')
                break
            case 'contactdetails':
                setApiValue('users/contactdetails')
                break
            case 'credentialdetails':
                setApiValue('users/credentialdetails')
                break
            case 'roledetails':
                setApiValue('users/roledetails')
                break
            case 'studentdetails':
                setApiValue('users/studentdetails')
                break
            default:
                break
        }

        setEditMode(prmType)

    }

    const handleOnCloseNoSave = () => {
        if (editMode !== '') {
            setEditMode('')
        } else {
            onCloseNoSave
        }

    }

    const handleOnCloseSave = (prmData: any, prmMethod: Operation, prmError?: string) => {
        if (editMode !== '') {
            onClose(prmData, prmMethod, prmError)
            setEditMode('')
        } else {
            onClose
        }

    }
    return (

        <>





            <UserFormEditor
                item={item}
                mode={mode}
                operation={operation}
                handleOnCloseNoSave={handleOnCloseNoSave}
                handleOnCloseSave={handleOnCloseSave}
                editMode={editMode}
                apiValue={apiValue}
                onImageUpdated={handlImageUpdated}
                handleEditCard={handleEditCard}
            />



            <UserFormAdd
                editMode={editMode}
                item={item}
                mode={mode}
                operation={operation}
                onClose={onClose}
                handleOnCloseNoSave={onCloseNoSave}
                onImageUpdated={handlImageUpdated}
                counters={counters}
                handleEditCard={handleEditCard}

            />

        </>
    )
}

export default UserForm