import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Transition component for dialog animation
const Transition = React.forwardRef(function Transition(
    props: any,
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type CommonDialogDialogProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    children: ReactNode;
};

const CommonDialog: React.FC<CommonDialogDialogProps> = ({ open, onClose, title, children }) => {


    return (
        <Dialog
            id="common-dialog"
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            maxWidth="sm"
            sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '8px',
                    color: 'blue',
                    position: 'relative',
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        left: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <span>{title}</span>

            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default CommonDialog;
