import './_CareForm.scss'
import { ICare } from '../ICare'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import SaveCancelToolBar from '../../../../Common/SaveCancelToolBar/SaveCancelToolBar'
import FooterToolBar from '../../../../Common/FooterToolBar/FooterToolBar'
import CareFormDetails from './Details/CareFormDetails'
import { useForm } from 'react-hook-form'
import { UFFormatDate } from '../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import { UFFormatTime } from '../../../../Common/UseForm/UFTimePicker/UFTimePicker'
import React from 'react'
import CommonTransition from '../../../../Common/CommonTransition/CommonTransition'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import CareFormat from '../List/ListItemTextAttributes'
import { useAuth } from '../../../../../contexts/AuthContext'
import { Box, useTheme } from '@mui/material'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import { Methods } from '../../../../../Utils/Utils'
import { useAlert } from '../../../../../contexts/AlertContext'




const CareForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const theme = useTheme();
    const { user } = useAuth()
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const methods = useForm<ICare>({ defaultValues: item })
    const { handleSubmit, control, setValue, watch } = methods
    const [tabValue, setTabValue] = useState('1')
    const { setAlert } = useAlert();
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0' })
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    useEffect(() => {
        const getCountersData = async () => {
            const urls = [
                'cares/' + item.CareID + '/students?count=1',
            ];

            try {
                const countersData = await Methods.getCountersBulk(urls);

                setCounters(prevCounters => ({
                    ...prevCounters,
                    ...countersData
                }));
            } catch (error) {
                setAlert({
                    message: t('Error')
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });

            }
        };
        if (mode === Mode.DeleteEdit) {
            getCountersData();
        }
    }, []);

    return (
        <Dialog open={true} onClose={onCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
            <SaveCancelToolBar
                api="Cares"
                idName="CareID"
                title={
                    operation === Operation.Add
                        ? t('Care')
                        : UFFormatDate(item.Date) + ' ' + UFFormatTime(item.Hour) + ' ' + t('Care')
                }
                item={item}
                mode={mode}
                operation={operation}
                onSave={onClose}
                onCloseNoSave={onCloseNoSave}
                handleSubmit={handleSubmit}
            />

            <DialogContent>
                <TabContext value={tabValue}>
                    <TabList variant="scrollable" onChange={handleTabChange} sx={theme.customSx.globalFormTabList}>
                        <Tab
                            label={t('Details') + ' (' + counters.students + ' ' + t('StudentCount') + ')'}
                            value="1"
                            icon={<InfoIcon />}
                        />
                        <Tab label={t('Cares') + '(' + counters.cares + ')'} value="2" icon={<ChildFriendlyIcon />} />
                    </TabList>

                    <Box sx={theme.customSx.globalFormTab(tabValue === '1')}>
                        <CareFormDetails
                            mode={mode}
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            item={item}
                            disabled={operation === Operation.Delete ? true : false}
                        />
                    </Box>
                    <Box sx={theme.customSx.globalFormTab(tabValue === '2')}>
                        <CommonInfiniteScrollList
                            url={'organizations/' + user!.OrganizationID + '/cares'}
                            isVisibleNow={tabValue === '2'}
                            listItemTextAttributes={CareFormat}
                            CRUDEForm={CareForm}
                            hideAddition={true}
                            CRUDEFormURL='care'
                        />
                    </Box>
                </TabContext>
            </DialogContent>
            {mode === Mode.DeleteEdit ? (
                <FooterToolBar
                    primaryKeyName={'CareID'}
                    primaryKeyValue={item.CareID}
                    name={item.Date + ' ' + item.Hour}
                    api="cares"
                    onSave={onClose}
                    item={item}
                />
            ) : null}
        </Dialog>
    )
}

export default CareForm
