import './_CommonConfirmation.scss'
import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface CommonConfirmationProps {
    openConfirmation: boolean
    message: string
    onConfirm: () => void
    onCancel: () => void
}

const CommonConfirmation: React.FC<CommonConfirmationProps> = ({ openConfirmation, message, onConfirm, onCancel }) => {
    const handleClose = () => {
        onCancel()
    }

    const handleConfirm = () => {
        onConfirm()
    }

    const { t } = useTranslation()

    return (
        <Dialog id="CommonConfirmation" open={openConfirmation} onClose={handleClose} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
            <DialogTitle>{t('Confirmation')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CommonConfirmation
