// ActionsPanel.tsx
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CallToActionIcon from '@mui/icons-material/CallToAction';
interface Action {
    label: string;
    onClick: () => void;
}

interface ActionsPanelProps {
    actions: Action[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CommonActionsPanel: React.FC<ActionsPanelProps> = ({ actions }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>
                <CallToActionIcon />
            </Button>
            <Dialog
                id="actions-panel"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="actions-panel-description"
            >
                <DialogContent>
                    {actions.map((action, index) => (
                        <Button
                            key={index}
                            onClick={() => {
                                action.onClick();
                                handleClose();
                            }}
                            fullWidth
                            variant="outlined"
                            style={{ margin: '10px 0' }}
                        >
                            {action.label}
                        </Button>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CommonActionsPanel;
