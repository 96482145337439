
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AlertContextProps {
    alert: {
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info' | null
        datetime: string;
    };
    setAlert: (alert: { message: string; severity: 'success' | 'error' | 'warning' | 'info' | null; datetime: string }) => void;
    clearAlert: () => void;
    showAlert: (message: string, severity: 'error' | 'warning' | 'info' | 'success') => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
    const [alert, setAlertState] = useState<{ message: string; severity: 'success' | 'error' | 'warning' | 'info' | null; datetime: string }>({ message: '', severity: null, datetime: '' });

    const setAlert = (alert: {
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info' | null;
        datetime: string;
    }) => {
        setAlertState(alert);


        if (alert.severity !== 'error') {
            setTimeout(() => {
                clearAlert();

            }, 3000); // Alert disappears after 3 seconds    
        }

    };

    const clearAlert = () => {
        setAlertState({ message: '', severity: null, datetime: '' });
    };

    const showAlert = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setAlert({ message, severity, datetime: new Date().toISOString() });
    };

    return (
        <AlertContext.Provider value={{ alert, setAlert, clearAlert, showAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};
