// SocketContext.tsx
/*import React, { createContext, useContext, useEffect, useState, useCallback, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

interface SocketContextType {
    socket: Socket | null;
    connectSocket: () => Promise<void>;
    disconnectSocket: () => void;
}

interface SocketProviderProps {
    children: ReactNode;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

let socket: Socket | null = null;

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);

    const connectSocket = useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (socket && socket.connected) {
                console.log('Socket connected:', socket.id);
                setIsConnected(true);
                resolve();
                return;
            }

            socket = io(process.env.REACT_APP_API_URL + ''); // Replace with your server URL

            socket.on('connect', () => {
                console.log('Socket connected:', socket?.id);
                setIsConnected(true);
                resolve();
            });

            socket.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
                setIsConnected(false);
                reject(error);
            });

            socket.on('disconnect', () => {
                console.log('Socket disconnected');
                setIsConnected(false);
            });



        });
    }, []);

    const disconnectSocket = useCallback(() => {
        if (socket) {
            console.log('Disconnecting socket:', socket.id);
            socket.disconnect();
            setIsConnected(false);
        }
    }, []);

    useEffect(() => {
        // Optionally connect the socket when the provider mounts
        connectSocket()
            .then(() => console.log('Initial connection established'))
            .catch((error) => console.error('Initial connection error:', error));

        // Cleanup on unmount
        return () => {
            disconnectSocket();
        };
    }, [connectSocket, disconnectSocket]);

    return (
        <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket }}>
            {children}
        </SocketContext.Provider>
    );
};*/

// SocketContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

interface SocketContextType {
    socket: Socket | null;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

interface SocketProviderProps {
    children: ReactNode;
}


export const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        console.log('Connecting socket');
        const newSocket = io(process.env.REACT_APP_API_URL + ''); // Replace with your server URL
        setSocket(newSocket);

        // Cleanup on unmount
        return () => {
            console.log('Disconnecting socket:', newSocket.id)
            newSocket.close();
        };
    }, []);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};

