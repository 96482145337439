import './_SaveCancelToolBar.scss'
import { Mode, Operation } from '../Types/CommonType'
import { ICommon } from '../Types/ICommon'
import { Options } from '../Types/CommonHelper'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { useLoading } from '../../../contexts/LoadingContext'
import { useAlert } from '../../../contexts/AlertContext'
import { useAuth } from '../../../contexts/AuthContext'

type Handler = <T>(item: T, afterOp: Operation, error?: string) => void

interface SaveCancelToolBarProps {
    api: string
    item: ICommon
    idName: string
    title: string
    operation: Operation
    mode: Mode
    onSave: Handler //so we can close the modal
    onCloseNoSave: () => void
    handleSubmit: any
}

const SaveCancelToolBar = (props: SaveCancelToolBarProps) => {
    const { onSave, onCloseNoSave, api, operation, handleSubmit, title, idName } = props
    //const [user] = useuserContext()
    const { user } = useAuth()
    const { t } = useTranslation()
    const { setLoading } = useLoading();
    const { setAlert } = useAlert();
    const handleAdd = (prmItem: ICommon) => {
        const ignore = false
        const tmpURL = process.env.REACT_APP_METHODS_API + api
        const abortController = new AbortController()

        if (prmItem.OrganizationID === undefined) {
            prmItem.OrganizationID = user!.OrganizationID
        }

        fetch(tmpURL, {
            method: 'POST',
            body: JSON.stringify(prmItem),
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                const data = await response.json()

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    onSave(prmItem, Operation.Add, error)
                    setAlert({
                        message: error
                        , severity: 'error'
                        , datetime: Date.now().toString()
                    });
                    return Promise.reject(error)
                }

                if (!ignore) {
                    setAlert({
                        message: t('Record added successfully.')
                        , severity: 'success'
                        , datetime: Date.now().toString()
                    });
                    onSave(data, Operation.Add)

                }
            })
            .catch((error) => {
                onSave(prmItem, Operation.Add, error)
                console.error('There was an error!', error)
                setAlert({
                    message: error
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleEdit = (prmItem: ICommon) => {
        const ignore = false
        const tmpURL = process.env.REACT_APP_METHODS_API + api
        const abortController = new AbortController()
        if (prmItem.OrganizationID === undefined) {
            prmItem.OrganizationID = user!.OrganizationID
        }

        fetch(tmpURL, {
            method: 'PUT',
            body: JSON.stringify(prmItem),
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                const data = await response.json()

                // check for error response
                if (typeof response !== 'undefined') {
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status
                        onSave(prmItem, Operation.Edit, error)
                        setAlert({
                            message: error
                            , severity: 'error'
                            , datetime: Date.now().toString()
                        });
                        return Promise.reject(error)
                    }
                }


                if (!ignore) {
                    setAlert({
                        message: t('Record updated successfully.')
                        , severity: 'success'
                        , datetime: Date.now().toString()
                    });
                    onSave(data, Operation.Edit)
                }
            })
            .catch((error) => {
                onSave(prmItem, Operation.Edit, error)
                setAlert({
                    message: error
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                console.error('There was an error!', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleDelete = (prmItem: ICommon) => {
        // @ts-ignore
        const tmpID = prmItem[idName]
        const ignore = false
        const tmpURL =
            process.env.REACT_APP_METHODS_API +
            api +
            '/' +
            tmpID +
            '/' +
            prmItem.UserName +
            '/' +
            prmItem.OpUserID +
            '/' +
            user!.OrganizationID
        const abortController = new AbortController()

        fetch(tmpURL, {
            method: 'DELETE',
            body: JSON.stringify(prmItem),
            signal: abortController.signal,
            headers: Options.headers,
        })
            .then(async (response) => {
                const data = await response.json()

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status
                    onSave(prmItem, Operation.Delete, error)
                    setAlert({
                        message: error
                        , severity: 'error'
                        , datetime: Date.now().toString()
                    });
                    return Promise.reject(error)
                }

                if (!ignore) {
                    setAlert({
                        message: t('Record deleted successfully.')
                        , severity: 'success'
                        , datetime: Date.now().toString()
                    });
                    onSave(data, Operation.Delete)
                }
            })
            .catch((error) => {
                onSave(prmItem, Operation.Delete, error)
                setAlert({
                    message: error
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
                console.error('There was an error!', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    //const handleSave = (event: React.FormEvent<HTMLButtonElement>) => {
    const handleSave = (data: ICommon) => {
        //event.preventDefault();
        setLoading(true)
        data.UserName = user!.userName
        data.OpUserID = user!.OpUserID
        data.OrganizationID = data.OrganizationID === -1 ? user!.OrganizationID : data.OrganizationID
        switch (operation) {
            case Operation.Add:
                handleAdd(data)
                break
            case Operation.Edit:
                handleEdit(data)
                break
            case Operation.Delete:
                handleDelete(data)

                break
            default:
        }
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={onCloseNoSave}
                        sx={{ mr: 2 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {t(Operation[operation]) + ' ' + title}
                    </Typography>

                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleSubmit(handleSave)}
                            color="inherit"
                        >
                            <DoneIcon />

                        </IconButton>
                    </div>
                </Toolbar>

            </AppBar>
        </>
    )
}
export default SaveCancelToolBar
