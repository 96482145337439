import { ALLERGIESICON, LEVELSICON, CARESICON, MEALSICON } from "../../../../../styles/_icons"
import CustomSVG from "../../../../Common/CustomSVG/CustomSVG"
import { IStudent } from "../IStudent"
import { Typography } from '@mui/material'

export const ListItemTextAttributes = (item: IStudent) => {
    //const { t } = useTranslation()
    return ({
        ...item,
        checked: false,
        ProfileImg: item.ProfileImg,
        primaryText:
            (item.Name ? item.Name : '') +
            ' ' +
            (item.Surname1 ? item.Surname1 : '') +
            ' ' +
            (item.Surname2 ? item.Surname2 : ''),
        newSecondaryText: (
            <Typography component="span" variant="body2" color="textSecondary">
                {Boolean(item.SpecialReqs) === true && <div className="icon-container">
                    <div className='icon-alert'>
                        <CustomSVG strSVG={ALLERGIESICON} />
                    </div>
                    {'Please see the special requirements'}
                </div>}

                <div className="icon-container">
                    <div className='icon'>
                        <CustomSVG strSVG={LEVELSICON} />
                    </div>
                    {item.LevelDSCR}
                </div>



                {<div className="icon-container">
                    <div className='icon'>
                        <CustomSVG strSVG={CARESICON} />
                    </div>
                    {item.CaresCount + ''}
                </div>}
                {<div className="icon-container">
                    <div className='icon'>
                        <CustomSVG strSVG={MEALSICON} />
                    </div>
                    {item.MealsCount + ''}
                </div>}
            </Typography>
        ),
    })
}

export default ListItemTextAttributes