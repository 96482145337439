import './_UFTimePicker.scss'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { TextField } from '@mui/material'
//import { time } from 'console';

export const UFFormatTime = (timeValue: any) => {
    if (timeValue !== undefined && timeValue !== '') {
        if (typeof timeValue === 'object') {
            if (typeof timeValue.format === 'function') {
                timeValue = timeValue.format('HH:mm')
            } else {
                timeValue = timeValue.toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
            }
        } else {
            if (timeValue.indexOf('/') > -1) {
                timeValue = timeValue.split(' ')[1]
            } else {
                if (timeValue.length > 5) {
                    timeValue = new Date(timeValue)
                    timeValue = timeValue.toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
                }
            }
        }
    } else {
        if (timeValue === '') {
            timeValue = new Date()
            timeValue = timeValue.toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
        } else {
            if (timeValue.indexOf('/') > -1) {
                timeValue = timeValue.split(' ')[1]
            } else {
                if (timeValue.length > 5) {
                    timeValue = new Date(timeValue)
                    timeValue = timeValue.toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
                }
            }
        }
    }
    return timeValue
}

const UFTimePicker = (props: IInputProps) => {
    const { name, control, label } = props

    return (
        <Controller
            name={name}
            control={control}
            //defaultValue={((value === undefined || value === '') ? new Date() : value)}
            defaultValue={new Date()}
            render={({ field: { onChange, value } }) => (
                <div style={{ width: '100%', marginTop: '1rem' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            data-test={name}
                            ampm={false}
                            label={label}
                            renderInput={(params) => <TextField {...params} fullWidth={true} />}
                            onChange={(time) => {
                                onChange(time)
                            }}
                            value={value === undefined || value === '' ? new Date() : new Date(value)}
                        />
                    </LocalizationProvider>
                </div>
            )}
        />
    )
}

export default UFTimePicker
