import './_UserFormRoleDetails.scss'
import UFSelect from '../../../../../../Common/UseForm/UFSelect/UFSelect'
import { useTranslation } from 'react-i18next'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { IUser } from '../../../IUser'

import { useAuth } from '../../../../../../../contexts/AuthContext'

interface UserFormRoleDetailsProps {
    control: any
    disabled: boolean
    setValue: any
    item: IUser

}

const UserFormRoleDetails = (props: UserFormRoleDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    //const [user] = useuserContext()
    const { user } = useAuth()



    return (
        <div style={{ width: '100%', marginBottom: '3rem' }}>
            <DivFlex variant="">



                <UFSelect
                    setValue={setValue}
                    name="OrganizationID"
                    control={control}
                    label={t('Organization')}
                    idColName="OrganizationID"
                    nameColName="Name"
                    api={"organizations"}
                    disabled={disabled}
                    required={t('Required value')}
                />
                <UFSelect
                    setValue={setValue}
                    name="RoleID"
                    control={control}
                    label={t('Role')}
                    idColName="RoleID"
                    nameColName="Name"
                    api={"organizations/" + user!.OrganizationID + "/roles"}
                    disabled={disabled}
                    required={t('Required value')}
                />




            </DivFlex>
        </div>
    )
}

export default UserFormRoleDetails
