import './_CareFormDetails.scss'
import { ICare } from '../../ICare'
import UFDatePicker, { UFFormatDate } from '../../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import UFTimePicker, { UFFormatTime } from '../../../../../Common/UseForm/UFTimePicker/UFTimePicker'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFMultiSelect from '../../../../../Common/UseForm/UFMultiSelect/UFMultiSelect'
import { useTranslation } from 'react-i18next'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Mode } from '../../../../../Common/Types/CommonType'
import { useAuth } from '../../../../../../contexts/AuthContext'



const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        width: '100%',
        marginTop: '1rem',
    })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

interface CareFormDetailsProps {
    item: ICare
    control: any
    watch: any
    setValue: any
    disabled: boolean
    mode: Mode

}

const CareFormDetails = (props: CareFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item, watch, mode } = props
    const [expanded, setExpanded] = useState<string | false>('')
    //const [user] = useuserContext()
    const { user } = useAuth()
    const handleRefreshIfJustOneElement = () => {
        //so we force the parent rerender if there is just element in the dropdown and this way we display the students
        setExpanded(false)
    }

    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }


    return (
        <div style={{ width: '100%' }}>
            <DivFlex variant="">
                <Accordion expanded={expanded === 'panel0'} onChange={handleChangeAccordion('panel0')}>
                    <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
                        {t('Care') +
                            ' ' +
                            UFFormatDate(watch('Date')) +
                            ' ' +
                            UFFormatTime(watch('Hour')) +
                            ' ' +
                            (watch('CareUserIDLabel') !== undefined ? t('by') + ' ' + watch('CareUserIDLabel') : '')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <UFSelect
                            name="CareUserID"
                            control={control}
                            setValue={setValue}
                            label={t('CareUserName')}
                            idColName="UserID"
                            nameColName="Name"
                            api={'organizations/' + user!.OrganizationID + '/users'}
                            disabled={disabled}
                        />
                        <UFDatePicker
                            control={control}
                            fullWidth={true}
                            name="Date"
                            label={t('Date')}
                            required={t('Required value')}
                            disabled={disabled}
                        />
                        <UFTimePicker
                            control={control}
                            fullWidth={true}
                            name="Hour"
                            label={t('Hour')}
                            required={t('Required value')}
                            disabled={disabled}
                        />
                    </AccordionDetails>
                </Accordion>
                {mode === Mode.Add && (
                    <UFSelect
                        name="CareGroupID"
                        setValue={setValue}
                        control={control}
                        label={t('CareGroup')}
                        idColName="CareGroupID"
                        nameColName="Name"
                        api={(user!.isSuperUser ? "organizations" : "organization/" + user!.OrganizationID.toString())}
                        disabled={(user!.isSuperUser ? disabled : true)}
                        handleRefreshIfJustOneElement={handleRefreshIfJustOneElement}
                        value={user!.OrganizationID.toString()}
                    />
                )}

                {watch('CareGroupID') !== undefined && watch('CareGroupID') !== '-1' && mode === Mode.Add && (
                    <UFMultiSelect
                        name="Students"
                        control={control}
                        setValue={setValue}
                        label={t('Students')}
                        idColName="StudentID"
                        nameColName="Name"
                        api={'caregroups/' + watch('CareGroupID') + '/students'}
                        parentApi={'organizations/' + user!.OrganizationID + '/students'}
                        required={t('Required value')}
                        displayMoreItemsButton={true}
                        moreItemsApi={'organizations/' + user!.OrganizationID + '/students'}
                        disabled={disabled}
                        selectAll={true}
                        startSelectedItems={[]}
                    />
                )}

                {mode === Mode.DeleteEdit && (
                    <UFMultiSelect
                        watch={watch}
                        name="Students"
                        control={control}
                        setValue={setValue}
                        label={t('Students')}
                        idColName="StudentID"
                        nameColName="Name"
                        api={'organizations/' + user!.OrganizationID + '/students'}
                        parentApi={'cares/' + item.CareID + '/students'}
                        required={t('Required value')}
                        displayMoreItemsButton={true}
                        moreItemsApi={'organizations/' + user!.OrganizationID + '/students'}
                        disabled={disabled}
                        startSelectedItems={[]}
                    />
                )}
            </DivFlex>
        </div>
    )
}

export default CareFormDetails
