import { ReactNode } from 'react'
import './_OnboardingWrapper.scss'
import { Box, Container, Grid } from '@mui/material'
import Copyright from '../Copyright/Copyright'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

interface onboardingWrapperProps {
    children?: ReactNode
}

const OnboardingWrapper = (props: onboardingWrapperProps) => {
    const { children } = props
    return (
        <>
            <Container component="main" maxWidth="xs">
                <Grid container display="flex" justifyContent="center" alignContent="center" className='eskolaLogo'>
                    <img src="/assets/images/theme/logo-eskola.svg" alt="Eskola brand" />
                </Grid>
                {children}
                <Box sx={{ mt: 8 }} >
                    <LanguageSelector />
                </Box>
            </Container>
            <Copyright />
        </>
    )

}

export default OnboardingWrapper
