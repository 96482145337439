
type PieData = {
    name: string;
    value: number;
};


interface ItemListState<T> {
    Items: T[]
    ItemsLoading: boolean
    ItemsLoaded: boolean
    error: boolean
    ItemSelected: boolean
    ItemCreationRequested: boolean
    id: number
    page: number
    hasMore: boolean
    displaySpinner: boolean
    filter: string
    viewMode: string
    componentParams?: any
    GraphItems: PieData[]
}
export const initialItemState = <T,>(prmComponentParams: any): ItemListState<T> => ({
    Items: [],
    ItemsLoaded: false,
    ItemsLoading: true,
    error: false,
    ItemSelected: false,
    ItemCreationRequested: false,
    id: -1,
    page: 1,
    hasMore: true,
    displaySpinner: true,
    filter: '',
    viewMode: 'list',
    componentParams: prmComponentParams,
    GraphItems: []
});

type ItemListActions<T> =
    | { type: 'Reset', componentParams?: JSON | null }
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: T[] }
    | { type: 'AddItem'; payload: T }
    | { type: 'UpdateItem'; payload: T; primarykey: string }
    | { type: 'RemoveItems'; payload: number[]; primarykey: string }
    | { type: 'SelectItem'; id: number }
    | { type: 'CreateItem' }
    | { type: 'CloseItem' }
    | { type: 'ActionExecuted'; payload: string }
    | { type: 'Error'; payload: string }
    | { type: 'NextPage'; page: number }
    | { type: 'HasMore'; hasMore: boolean }
    | { type: 'UpdateFilter'; payload: string }
    | { type: 'NoData' }
    | { type: 'SessionExpired' }
    | { type: 'ChangeViewMode'; payload: string }
    | { type: 'LoadGraphItems'; payload: PieData[] }

const removeItemArray = <T,>(currentItems: T[], ids: number[], primarykey: string): T[] => {
    const updatedArray: T[] = []

    if (currentItems && currentItems.length > 0) {
        currentItems.forEach((Item: T) => {
            // @ts-ignore
            if (!ids.find((id) => id === Item[primarykey])) {
                updatedArray.push(Item)
            }
        })
    }

    return updatedArray
}

export const ItemReducer = <T,>(state: ItemListState<T>, action: ItemListActions<T>): ItemListState<T> => {


    switch (action.type) {
        case 'Reset':
            return initialItemState(action.componentParams)
        case 'LoadItems': {


            return { ...state, ItemsLoading: true, displaySpinner: true, ItemsLoaded: false }
        }
        case 'ItemsLoaded': {

            return {
                ...state,
                Items: (typeof state.page != 'undefined' ? [...state.Items, ...action.payload] : action.payload),
                ItemsLoading: false,
                ItemsLoaded: true,
                error: false,
                ItemSelected: false,
                ItemCreationRequested: false,
                page: (typeof state.page != 'undefined' ? state.page + 1 : state.page)
            }
        }
        case 'AddItem': {


            return {
                ...state,
                ItemCreationRequested: false,
                //Items: (state.Items.length === 1 && Object.keys(state.Items[0])[0] === 'num' ? (Object.values(state.Items[0])[0] = Object.values(state.Items[0])[0] + 1) : [action.payload,...state.Items]),
                Items: [action.payload, ...state.Items],
                ItemsLoading: false,
            }
        }
        case 'UpdateItem': {


            return {
                ...state,
                ItemSelected: false,
                Items: state.Items.map((Item: T) => {
                    // @ts-ignore
                    if (Item[action.primarykey] === action.payload[action.primarykey]) {
                        return action.payload
                    } else {
                        return Item
                    }
                }),
                ItemsLoading: false,
            }
        }
        case 'RemoveItems': {


            return {
                ...state,
                ItemSelected: false,
                Items: removeItemArray(state.Items, action.payload, action.primarykey),
                ItemsLoading: false,
            }
        }
        case 'SelectItem':
            return { ...state, ItemSelected: true, id: action.id }
        case 'CreateItem':
            return { ...state, ItemCreationRequested: true }
        case 'Error': {

            return {
                ...state,
                error: true,
                ItemsLoading: false,
                ItemsLoaded: false

            }
        }
        case 'CloseItem': {

            return {
                ...state,
                error: false,
                ItemsLoading: false,
                ItemsLoaded: true,
                ItemSelected: false,
                ItemCreationRequested: false,
            }
        }
        case 'ActionExecuted': {

            return {
                ...state

            }
        }
        case 'NextPage': {

            return {
                ...state,
                page: action.page,

            }
        }
        case 'HasMore': {

            return {
                ...state,
                hasMore: action.hasMore,

            }
        }
        case 'UpdateFilter': {
            return {
                ...state,
                Items: [],
                ItemsLoaded: false,
                ItemsLoading: true,
                error: false,
                displaySpinner: false,
                ItemSelected: false,
                ItemCreationRequested: false,
                id: -1,
                page: 1,
                hasMore: true,
                filter: action.payload,

            }
        }
        case 'NoData': {

            return {
                ...state,
                page: -1

            }
        }
        case 'SessionExpired': {

            return {
                ...state,
                page: -666

            }
        }
        case 'ChangeViewMode': {

            return {
                ...state,
                viewMode: action.payload

            }
        }
        case 'LoadGraphItems': {

            return {
                ...state,
                GraphItems: action.payload

            }
        }
        default:
            return state
    }
}
