
import './_UserFormStudentDetails.scss'
import { useTranslation } from 'react-i18next'

import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { IUser } from '../../../IUser'

import UFAutocomplete from '../../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useAuth } from '../../../../../../../contexts/AuthContext'



interface UserFormStudentDetailsProps {
    control: any
    disabled: boolean
    setValue: any
    item: IUser

}

const UserFormStudentDetails = (props: UserFormStudentDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    //const [session] = useSessionContext()
    const { user } = useAuth()


    return (
        <div style={{ width: '100%', marginBottom: '3rem' }}>
            <DivFlex variant="">




                <UFAutocomplete
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    api={'organizations/' + user!.OrganizationID + '/students'}
                    parentApi={'users/' + item.UserID + '/students'}
                    disabled={disabled}
                    chipImageName="ProfileImg"
                    startSelectedItems={[]}
                />


            </DivFlex>
        </div>
    )
}

export default UserFormStudentDetails
