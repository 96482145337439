
import MainApp from './MainApp'


const AppNew = () => {
    return (
        <MainApp />
    )
}

export default AppNew
