
import { useTranslation } from "react-i18next"
import { Mode, Operation } from "../../../../../../Common/Types/CommonType"
import { IUser } from "../../../IUser"
import { Dialog, DialogContent } from "@mui/material"
import SaveCancelToolBar from "../../../../../../Common/SaveCancelToolBar/SaveCancelToolBar"
import { useForm } from "react-hook-form"
import CommonTransition from "../../../../../../Common/CommonTransition/CommonTransition"
import UserFormRoleDetails from "../../RoleDetails/Details/UserFormRoleDetails"
import UserFormStudentDetails from "../../StudentDetails/Details/UserFormStudentDetails"
import UserFormNameDetails from "../../NameDetails/Details/UserFormNameDetails"
import UserFormContactDetails from "../../ContactDetails/Details/UserFormContactDetails"
import UserFormCredentialDetails from "../../CredentialDetails/Details/UserFormCredentialDetails"



interface UserFormEditorProps {
    item: IUser
    mode: Mode
    operation: Operation
    handleOnCloseNoSave: () => void
    handleOnCloseSave: <T>(item: T, afterOp: Operation, error?: string) => void
    editMode: string
    apiValue: string
    onImageUpdated: (filename: string) => void
    handleEditCard: (prmType: string) => void
}

const UserFormEditor = (props: UserFormEditorProps) => {
    const { item, mode, operation, handleOnCloseNoSave, handleOnCloseSave, editMode, apiValue, onImageUpdated, handleEditCard } = props
    const methods = useForm<IUser>({ defaultValues: item })
    const { handleSubmit, control, setValue } = methods
    const { t } = useTranslation()

    return (
        <Dialog open={editMode !== ''} onClose={handleOnCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
            <SaveCancelToolBar
                api={apiValue}
                idName="UserID"
                title={
                    item.Name + ' ' + item.Surname1 + ' ' + item.Surname2 + ' ' + t('User')
                }
                item={item}
                mode={mode}
                operation={operation}
                onSave={handleOnCloseSave}
                onCloseNoSave={handleOnCloseNoSave}
                handleSubmit={handleSubmit}
            />



            <DialogContent>


                {editMode === 'namedetails' && <UserFormNameDetails
                    control={control}
                    item={item}
                    setValue={setValue}
                    disabled={operation === Operation.Delete ? true : false}

                />
                }

                {editMode === 'contactdetails' && <UserFormContactDetails
                    control={control}
                    item={item}
                    setValue={setValue}
                    disabled={operation === Operation.Delete ? true : false}

                />
                }

                {editMode === 'credentialdetails' && <UserFormCredentialDetails
                    control={control}
                    item={item}
                    setValue={setValue}
                    disabled={operation === Operation.Delete ? true : false}

                />
                }

                {editMode === 'roledetails' && <UserFormRoleDetails
                    control={control}
                    item={item}
                    setValue={setValue}
                    disabled={operation === Operation.Delete ? true : false}

                />
                }

                {editMode === 'studentdetails' && <UserFormStudentDetails
                    control={control}
                    item={item}
                    setValue={setValue}
                    disabled={operation === Operation.Delete ? true : false}

                />}



            </DialogContent>
        </Dialog>
    )
}

export default UserFormEditor