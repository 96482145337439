import { createTheme, styled } from '@mui/material/'
// import { blue } from '@mui/material/colors'
import type { } from '@mui/x-data-grid/themeAugmentation' //for the datagrid


/*declare module '@mui/material/styles' {
    interface div {
        variants?: any
    }
}*/

declare module '@mui/material/styles' {
    interface Theme {
        customSx: {
            [key: string]: any;
            globalFormTab: (isVisible: boolean) => any;
        };
    }

    interface ThemeOptions {
        customSx?: {
            [key: string]: any;
            globalFormTab: (isVisible: boolean) => any;
        };

    }
}


const eskolaTheme = createTheme({
    // Customize the components
    components: {
        // Generic CSS tokens
        MuiCssBaseline: {
            styleOverrides: {
                ':root': {
                    '--black': '#000',
                    '--white': '#fff',
                    '--primary-main': '#1D535C',
                    '--primary-light': '#4A757D',
                    '--primary-dark': '#17424A',
                    '--primary-contrast-text': '#fff',
                    '--secondary-main': '#4ECDC4',
                    '--secondary-light': '#ba68c8',
                    '--secondary-dark': '#7b1fa2',
                    '--secondary-contrast-text': '#fff',
                    '--error-main': '#d32f2f',
                    '--error-light': '#ef5350',
                    '--error-dark': '#c62828',
                    '--error-contrast-text': '#fff',
                    '--warning-main': '#ed6c02',
                    '--warning-light': '#ff9800',
                    '--warning-dark':'#e65100',
                    '--warning-contrast-text': '#fff',
                    '--info-main': '#0288d1',
                    '--info-light': '#03a9f4',
                    '--info-dark': '#01579b',
                    '--info-contrast-text': '#fff',
                    '--success-main': '#2e7d32',
                    '--success-light': '#4caf50',
                    '--success-dark': '#1b5e20',
                    '--success-contrast-text': '#fff',
                    '--grey-50': '#fafafa',
                    '--grey-100': '#f5f5f5',
                    '--grey-200': '#eeeeee',
                    '--grey-300': '#e0e0e0',
                    '--grey-400': '#bdbdbd',
                    '--grey-500': '#9e9e9e',
                    '--grey-600': '#757575',
                    '--grey-700': '#616161',
                    '--grey-800': '#424242',
                    '--grey-900': '#212121',
                    '--grey-A100': '#f5f5f5',
                    '--grey-A200': '#eeeeee',
                    '--grey-A400': '#bdbdbd',
                    '--grey-A700': '#616161',
                },
            },
        },
        // Custom the main container 
        MuiContainer: {
            styleOverrides: {
                root: {
                    border:'1px solid var(--secondary-main)',
                    marginTop:'1rem',
                    padding:'1rem',
                }
            }
        },
        // Data grid
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    //backgroundColor: 'red',
                    '&:hover, &.Mui-hovered': {
                        cursor: 'pointer',
                    },
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor:'var(--primary-main)',
                        cursor: 'pointer',
                    },
                },
            },
        },
        // Dialog
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--primary-main)',
                    color: 'var(--white)',
                    userSelect: 'none',
                },
            },
        },
        // Link
        MuiLink:{
            styleOverrides:{
                root:{
                    color:'var(--primary-dark)',
                    fontSize:'small',
                    '&:hover': {
                        color:'var(--primary-dark)',
                    },
                    '&:active': {
                        color:'var(--primary-main)',
                    },
                    '&:focus': {
                        color:'var(--primary-dark)',
                    },
                },
            },
        },
        // Button styles
        MuiButton:{
            styleOverrides: {
                root: {
                    borderRadius:4,
                    background:'var(--primary-main)',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                    },
                    '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    },
                },
            },
        },
        // Form
        MuiFormControl:{
            styleOverrides: {
                root: {
                    paddingBottom:'1rem',
                    '&:focus, &.Mui-focused': {
                    },
                },
            },
        },
        // Focus state
        MuiInputLabel:{
            styleOverrides: {
                root: {
                    '&:focus, &.Mui-focused': {
                    },
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides: {
                root: {
                    borderRadius:0,
                    backgroundColor:'var(--white)',
                    '&:focus, &.Mui-focused': {
                    },
                },
            },
        },
        MuiInputBase:{
            styleOverrides: {
                root: {
                    borderRadius:0,
                    backgroundColor:'var(--white)',
                    '&:focus, &.Mui-focused': {
                        borderColor:'var(--secondary-main)',
                    },
                },
            },
        },
        MuiInputAdornment:{
            styleOverrides: {
                root: {
                    padding:0,
                    backgroundColor:'var(--white)',
                    '&:focus, &.Mui-focused': {
                    },
                },
            },
        },
    },
    // // Breakpoints
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            //md: 900,
            md: 900,
            lg: 1200,
            xl: 1536
        },
    },
    // // Typography
    typography: {
        htmlFontSize: 16,
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700
    },
    // shape:{
    //     borderRadius: 4
    // },

    // customSx: {
    //     globalFormTabList: {
    //         position: 'fixed',
    //         top: '60px',
    //         left: 0,
    //         padding: '20px',
    //         width: '100%',
    //         backgroundColor: 'var(--white)',
    //         zIndex: (theme: any) => theme.zIndex.modal + 1,
    //         '@media (max-width: 600px)': {
    //             top: '56px', // Adjust this if your App Bar height changes
    //         },
    //     },
    //     globalFormTab: (isVisible: boolean) => ({
    //         display: isVisible ? 'block' : 'none',
    //         position: 'relative',
    //         top: '60px',
    //         padding: '20px',
    //         width: '100%',
    //         '@media (max-width: 600px)': {
    //             top: '55px', // Adjust this if your App Bar height changes
    //         }
    //     }),


    // }
})

/*const ListItemText = styled(MuiListItemText)({
    '& .MuiListItemText-primary': {
      color: 'orange',
    },
    '& .MuiListItemText-secondary': {
      color: 'gray',
    },
  });*/

export interface DivFlexProps {
    variant?: '' | 'desktop'
}

export const DivFlex = styled('div', {
    // Configure which props should be forwarded on DOM
    shouldForwardProp: (prop) => prop !== 'variant',
    /*name: 'DivFlex',
    slot: 'Root',*/
    // We are specifying here how the styleOverrides are being applied based on props
    /*overridesResolver: (props, styles) => [
        styles.root
    ],*/
    name: 'MuiDivFlex',
    slot: 'Root',
    skipVariantsResolver: true, // disables theme.components[name].variants
    //skipSx: true,
})<DivFlexProps>(({ theme, variant }) => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    flexDirection: variant === '' ? 'column' : 'row',
    padding: '1rem',
    marginTop: '1rem',
    //bgcolor: theme.palette.primary,
    //backgroundColor: 'LIGHTblue',
    borderRadius: 1,
}))

export default eskolaTheme
