import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, styled, useTheme } from "@mui/material";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { IUser } from "../../../Views/Administration/User/IUser";
import { IMenu } from "../../../Views/Administration/Menu/IMenu";
import CustomDrawerLanguagesMenu from "../CustomDrawerLanguagesMenu/CustomDrawerLanguagesMenu";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoutIcon from '@mui/icons-material/Logout'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CustomSVG from "../../CustomSVG/CustomSVG";
import { Methods } from "../../../../Utils/Utils";
import CustomDrawerSingleLevel from "../CustomDrawerSingleLevel/CustomDrawerSingleLevel";
import { useSocket } from "../../../../contexts/SocketContext";
import { useAuth } from "../../../../contexts/AuthContext";



const DrawerHeaderRight = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}))

interface CustomDrawerRightProps {

    openRight: boolean
    handleOpenRight(prm: boolean): void
    handleSetPageName(prm: any): void
    handleSetPageIcon(prm: any): void
    handleOpenMyProfile(): void
    handleOpenMyMessages(): void
}

const CustomDrawerRight = (props: CustomDrawerRightProps) => {
    const { t } = useTranslation()
    const { handleSetPageName, handleSetPageIcon, handleOpenRight, openRight, handleOpenMyProfile, handleOpenMyMessages } = props
    const [open, setOpen] = useState(false)
    //const [session, setSession] = useSessionContext()
    const { user, setUserDetails } = useAuth();
    const drawerWidth = 240
    const [openMyProfile, setOpenMyProfile] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme()
    const { socket } = useSocket();

    const MultiLevel = ({ item }: any) => {
        const { children } = item
        const [openMulti, setOpenMulti] = useState(false)
        const handleClick = () => {
            setOpenMulti((prev) => !prev)
        }

        return (
            <>
                <ListItem
                    key={item.MenuID}
                    disablePadding
                    sx={{
                        display: 'block',
                        zIndex: (theme) => theme.zIndex.modal + 1
                    }}
                    onClick={handleClick}
                    id={item.MenuID}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                ml: item.ParentMenuID != null ? 2 * item.MenuLevel + 'rem' : '3',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={t(item.Name)}>
                                <Box
                                    sx={{
                                        mr: '.5rem',
                                    }}
                                >
                                    <CustomSVG strSVG={item.Icon} />
                                </Box>
                            </Tooltip>
                        </ListItemIcon>

                        <ListItemText primary={t(item.Name)} />
                        {openMulti ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMulti} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((child: any, key: any) => (
                            <MenuItemCustom key={key} item={child} />
                        ))}
                    </List>
                </Collapse>
            </>
        )
    }

    const MenuItemCustom = useCallback(
        ({ item }: any) => {
            if (typeof item !== 'undefined') {
                const Component = Methods.hasChildren(item) ? MultiLevel : CustomDrawerSingleLevel
                if (Methods.hasChildren(item)) {
                    return <Component item={item} />
                } else {
                    return <Component
                        item={item}
                        open={open}
                        handleListItemClick={handleListItemClick}
                    />
                }

            } else {
                return <>aaa</>
            }
        },
        [user!.menus]
    )

    const handleDrawerCloseRight = () => {
        handleOpenRight(false)
    }

    const handleLogout = () => {
        localStorage.removeItem('auth')
        if (socket) {
            console.log('Removing user from socket')
            socket.emit('disconnect_user', user!.OpUserID);
        }
        setTimeout(() => {
            //setSession({ ...session, isAuthenticated: false, userName: '', menus: [] })
            setUserDetails({
                ...user,
                isAuthenticated: false,
                userName: '',
                menus: [],
                OpUserID: -1,
                OrganizationID: -1,
                OrganizationDSCR: '',
                redirectPath: '',
                isSuperUser: false,
                ProfileImg: '',
            })
            navigate('/login')
        }, 1000)
    }
    const handlImageUpdated = (filename: string) => {
        console.log('updated')
    }

    const handleOpenProfile = () => {
        //setOpenMyProfile(true)
        handleOpenMyProfile()

    }
    const handleListItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()

        const result = Methods.findInJSON(user!.menus, e.currentTarget.id, 'MenuID', 'children')
        switch (e.currentTarget.id) {
            case 'logout':
                setOpen(false)
                handleOpenRight(false)
                handleLogout()
                break
            case 'myProfile':

                //setOpenMyProfile(true)
                handleOpenRight(false)
                setOpen(false)
                handleOpenMyProfile()

                break
            case 'myMessages':

                //setOpenMyProfile(true)
                handleOpenRight(false)
                setOpen(false)
                handleOpenMyMessages()

                break
            case 'myDashboard':
                setOpenMyProfile(false)

                handleOpenRight(false)
                setOpen(false)
                navigate(user!.redirectPath)
                handleSetPageName(t('Eskola'))
                break
            case 'myControlPanel':
                setOpenMyProfile(false)

                handleOpenRight(false)
                setOpen(false)
                navigate('controlpanel')
                handleSetPageName(t('Eskola'))
                break

            default:
                setOpen(false)
                handleOpenRight(false)

                navigate(result.Route)
                handleSetPageName(result.Name)
                handleSetPageIcon(result.Icon)
                break
        }
        e.stopPropagation()
    }
    return (
        <Drawer
            sx={{

                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box'

                }

            }}

            //if we make temporary we could hide when tapping outside boundaries but it makes the central area wider
            //ask Nagore
            variant="temporary"
            //variant="persistent"
            anchor="right"
            open={openRight}
            onClose={handleDrawerCloseRight}
        >
            <DrawerHeaderRight>
                <IconButton onClick={handleDrawerCloseRight}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>

            </DrawerHeaderRight>
            <Divider />
            <List>
                <ListItem
                    key={'myProfile'}
                    disablePadding
                    sx={{ display: 'block' }}
                    onClick={handleListItemClick}
                    id={'myProfile'}
                >
                    <CustomAvatar
                        itemType="images/user"
                        keyName="OpUserID"
                        item={user! as unknown as IUser}
                        onImageUpdated={handlImageUpdated}
                        sizeHeight={100}
                        sizeWidth={100}
                        justViewMode={true}
                        hasIcon={true}
                        noPreview={true}
                        onClick={handleOpenProfile}

                    />
                </ListItem>
                <ListItem
                    key={'myDashboard'}
                    disablePadding
                    sx={{ display: 'block' }}
                    onClick={handleListItemClick}
                    id={'myDashboard'}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={t('My Dashboard')}>
                                <DashboardIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={t('My Dashboard')} sx={{ opacity: openRight ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                {/*<Divider />
            <ListItem
                key={'myMessages'}
                disablePadding
                sx={{ display: 'block' }}
                onClick={handleListItemClick}
                id={'myMessages'}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Tooltip title={t('My Messages')}>
                            <MessageIcon />
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={t('My Messages')} sx={{ opacity: openRight ? 1 : 0 }} />
                </ListItemButton>
                    </ListItem>*/}
                {user!.menus.filter((itm: IMenu) => (typeof itm.IsConfigMenu != 'undefined' && Boolean(itm.IsConfigMenu) === true)).length > 0 &&
                    <>
                        <Divider />

                        <List>
                            {user!.menus.map((item, key) => (

                                (Boolean(item.IsConfigMenu) === true || typeof item.IsConfigMenu === 'undefined') && <MenuItemCustom key={key} item={item} />
                            ))}
                        </List>
                    </>
                }
                <CustomDrawerLanguagesMenu />
                <Divider />
                <ListItem
                    key={'logout'}
                    disablePadding
                    sx={{ display: 'block' }}
                    onClick={handleListItemClick}
                    id={'logout'}
                >
                    <ListItemButton
                        data-test="drawer-logout-button"
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip title={t('Logout')}>
                                <LogoutIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={t('Logout')} sx={{ opacity: openRight ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    )
}
export default CustomDrawerRight;