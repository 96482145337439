import './_ResetPassword.scss'
import React, { useState } from 'react'
import { Button, Container, Grid, Typography } from '@mui/material'

import { useParams } from 'react-router-dom'
import { Options } from '../../../Common/Types/CommonHelper'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { IResetPassword } from './IResetPassword'
import { useForm } from 'react-hook-form'
import { useAlert } from '../../../../contexts/AlertContext'

interface ResetPasswordProps {
    token?: string
}

/*const ResetPassword: React.FC<ResetPasswordProps> = ({ token }) => {*/

const ResetPassword: React.FC<ResetPasswordProps> = () => {
    const [loading, setLoading] = useState(false)
    const { setAlert } = useAlert();
    /*const [alertSeverity, setAlertSeverity] = React.useState({
        status: '',
        msg: '',
    })*/
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    })

    const [confirmValues, setConfirmValues] = React.useState({
        password: '',
        showPassword: false,
    })

    const params = useParams()
    const token = params.token
    const theme = createTheme()
    const { t } = useTranslation()

    const methods = useForm<IResetPassword>({ defaultValues: { password: '', confirmpassword: '' } })
    const { handleSubmit, control, setValue } = methods


    const handleResetPassword = async (prm: IResetPassword) => {
        if (prm.password !== prm.confirmpassword) {
            //setAlertSeverity({ status: 'error', msg: t('Passwords do not match.') })
            setAlert({
                message: 'Passwords do not match.'
                , severity: 'error'
                , datetime: Date.now().toString()
            });
            return
        }

        setLoading(true)

        try {
            const prmItem = {
                Token: token,
                Password: prm.password,
            }

            const abortController = new AbortController()

            const response = await fetch(process.env.REACT_APP_METHODS_API + 'users/resetpassword', {
                method: 'POST',
                body: JSON.stringify(prmItem),
                signal: abortController.signal,
                headers: Options.headers,
            })

            if (!response.ok) {
                const errorData = await response.json()

                //setAlertSeverity({ status: 'error', msg: t(errorData.msg) })
                setAlert({
                    message: t(errorData.msg)
                    , severity: 'error'
                    , datetime: Date.now().toString()
                });
            } else {
                //setAlertSeverity({ status: 'OK', msg: t('Password is updated') })
                setAlert({
                    message: t('Password is updated')
                    , severity: 'success'
                    , datetime: Date.now().toString()
                });
                setValues({ ...values, password: '' })
                setConfirmValues({ ...confirmValues, password: '' })
                localStorage.clear()
            }
        } catch (err) {
            //setAlertSeverity({ status: 'error', msg: t('Error occurred. Please try again later.') })
            setAlert({
                message: t('Error occurred. Please try again later.')
                , severity: 'error'
                , datetime: Date.now().toString()
            });


        }

        setLoading(false)
    }

    return (
        <ThemeProvider theme={theme}>

            <Container
                maxWidth="sm"
                sx={{
                    marginTop: 8,
                }}
            >
                <form onSubmit={handleSubmit(handleResetPassword)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">
                                {t('Reset Password')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UFTextField
                                setValue={setValue}
                                control={control}
                                fullWidth={true}
                                name="password"
                                label={t('Password')}
                                required={t('Empty field!')}
                                autoComplete={'current-password'}
                                autoFocus={true}
                                inputType={EInputType.Password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UFTextField
                                setValue={setValue}
                                control={control}
                                fullWidth={true}
                                name="confirmpassword"
                                label={t('Confirm Password')}
                                required={t('Empty field!')}
                                autoComplete={'current-password'}
                                autoFocus={true}
                                inputType={EInputType.Password}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                                {loading ? 'Loading...' : 'Reset Password'}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2" color="textPrimary" align="left">
                                <Link style={{ textDecoration: 'none' }} to={'/login'}>
                                    {t('Home')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </ThemeProvider>
    )
}

export default ResetPassword
